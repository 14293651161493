import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  base: "/",
  routes: [
    { path: "/", redirect: "/index" },
    { path: "/controller", component: () => import("@/components/controller") },
    {
      path: "/index",
      redirect: "/home",
      component: () => import("@/components/index"),
      children: [
        { path: "/aboutUs", component: () => import("@/components/aboutUs") },
        { path: "/home", component: () => import("@/components/home") },
        { path: "/product", component: () => import("@/components/product") },

        { path: "/solution", component: () => import("@/components/solution") },
        { path: "/news", component: () => import("@/components/news") },
        { path: "/newsDetail", component: () => import("@/components/newsDetail") },
        { path: "/proDetail", component: () => import("@/components/proDetail") },
        { path: "/protea", component: () => import("@/components/protea") },
      ],
    },
  ],
});

// 获取原型对象push函数
const VueRouterPush = VueRouter.prototype.push
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router;
