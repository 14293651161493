import Vue from "vue";
import {
  Button,
  Container,
  Header,
  Main,
  Footer,
  Menu,
  MenuItem,
  Carousel,
  CarouselItem,
  Image,
  Upload,
  Progress,
  Message,
  Backtop,
  Tabs,
  TabPane,
  Pagination,

  Submenu,
  MenuItemGroup,
  Aside,
  Card,
  Tag,
} from "element-ui";

Vue.use(Button);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Backtop);
Vue.use(TabPane);
Vue.use(Tabs);
Vue.use(Pagination);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(Aside);
Vue.use(Card);
Vue.use(Tag);

Vue.prototype.$message = Message;

