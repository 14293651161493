import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
import "./css/global.css";
import data from "./data/data";
import "wowjs/css/libs/animate.css";
// import "./utils/flexible";

Vue.prototype.$EventBus = new Vue();
// import { monitorZoom } from "@/utils/monitorZoom.js";
// const m = monitorZoom();
// if (window.screen.width * window.devicePixelRatio >= 3840) {
//   document.body.style.zoom = 100 / (Number(m) / 2); // 屏幕为 4k 时
// } else {
//   document.body.style.zoom = 100 / Number(m);
// }

Vue.config.productionTip = false;

// 挂载data
Vue.prototype.data = data;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
