<template>
  <div id="app">
    <div class="websit">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
  </div>
</template>

<script>
// import devicePixelRatio from "./utils/devicePixeRatio";
export default {
  name: "app",
  created() {
    // new devicePixelRatio().init();
    // const isMo = window.innerWidth < 600
    // 判断当前设备是移动端还是pc端
    // if (isMo) { // 移动端
    //   return
    // }
    // else {// pc端,根据窗口缩放页面
    //   var zo = document.documentElement.clientWidth
    //   var scale = zo / 1920
    //   document.documentElement.style.zoom = scale
    // }
  },
};
</script>

<style>
.websit {
  width: 100%;
  height: 100%;
  display: flex;
}
</style>
