// 存放所有数据
var data = {
  //首页对象
  home: {
    //轮播图列表
    bannerList: [
      { src: require("../assets/banner/banner1.jpg") },
      { src: require("../assets/banner/banner2.jpg") },
      { src: require("../assets/banner/banner3.jpg") },

    ],
  },
  //关于我们对象
  //产品中心对象
  pro_center: {
    // 首页产品列表
    proList: [
      {
        id: 1,
        name: "认知能力测试与训练系统", //产品名称
        imgUrl: require("../assets/images/renzhinengliceshi.png"), //产品图片
        //产品详情描述
        detail: [
          "根据认知发展规律确定测验与训练内容，用以评价与提高用户认知能力的多功能系统，包括认知评估和认知训练两个功能模块。如常见的皮博迪图片词汇测验PPVT5、瑞文标准推理测验 SPM、神经行为认知状况测试 NCSE、洛文斯顿认知功能评定测验 LOTCA、行为忽略测验 BIT。",
        ],
        version: [], //版本
        apply:
          "记忆、注意力、反应速度、理解力、灵活性等领域研究，老年性痴呆（认知障碍）、学习障碍、ADD/ADHD等应用领域研究",
      },
      {
        id: 2,
        name: "孤独症儿童个别化教育计划训练评估系统", //产品名称
        imgUrl: require("../assets/images/guduzhnegertonggebiejioayu.png"), //产品图片
        //产品详情描述
        detail: [
          "系统对孤独症儿童的发展现状及需要进行评估，并为孤独症儿童的教育康复提供训练功能。将评估与教育康复训练相结合，在充分分析他们的发展水平、优势和不足的基础上，制定针对性的训练目标和训练计划，并对训练的效果进行动态的评估，以便及时调整训练的目标和计划，以保证孤独症教育康复训练的科学性和有效性。（使用中残联认定的孤独症量表评估）",
        ],
        apply: "孤独症教育课题研究",
        version: [], //版本
      },
      {
        id: 3,
        name: "儿童多元智能评估与教育指导系统", //产品名称
        imgUrl: require("../assets/images/ertongduoyuan.png"), //产品图片
        version: [], //版本
        detail: [
          "系统基于美国哈佛大学心理学家加德纳博士在 1983 年提出的多元智能理论，每个人都拥有不同的智能优势组合。通过专业量表评估儿童语言智能、数学逻辑智能、空间智能、身体运动智能、音乐智能、人际智能、自我认知智能、自然认知智能八大智能表现，找到儿童的优劣势发展，并为其提供个性化的教育指导，协助促进儿童的健康发展。",
        ], //产品详情描述
        apply:
          "高校儿童科研机构、儿童教育机构、儿童保健科、社区、各级健康体检中心等。", //适用于
      },
      {
        id: 4,
        name: "在线心理测评应用系统", //产品名称
        imgUrl: require("../assets/images/zaixianxinlicep.png"), //产品图片
        version: ["幼儿版", "中小学版", "大学版", "司法版", "体育专项"], //版本
        detail: [
          "系统响应国家“十四五”国民健康规划和健康中国行动，遵循预防为主、监测预警、及时响应、重在教育的基本原则，是一个数字化的互联网心理服务平台。",
          "三级评估，逐级聚焦，准确筛查目标群体。第一步：一般健康量表，评估主客观存在或影响" +
          "心理健康的因素；第二步：自杀或自伤行为评估，针对心理健康不佳的人员继续进行危险行为筛查，评估其发生危险行为的倾向；第三步：针对高危人员进行专业深度了解与干预。",
        ], //产品详情描述
        apply: "", //适用于
      },
    ],
    // 心理服务 ---类型列表
    products_1: [
      {
        id: 5,
        name: '中小学心理健康教育与干预云平台',
        imgUrl: require('../assets/images/zhongxiaoxue.png'),
        version: [],
        detail: [
          "系统响应国家“十四五”国民健康规划和健康中国行动，遵循预防为主、监测预警、及时响应、重在教育的基本原则，是一个数字化的互联网心理服务平台。主要包括心理健康筛查和教育两大模块。",
          "1.根据中小学生生理、心理发展特点和规律，采用三级评估，逐级聚焦，准确筛查目标群体，进而进行科学有效的心理辅导，及时给予必要的危机干预，提高学生调控情绪、承受挫折、适应环境的能力。",
          "2.系统提供科学化、专业化的心理健康知识，帮助学生了解相关知识解除疑问，同时也为教师提供教学与干预辅助工具，帮助教师进行心理健康教育工作。",
          "3.系统记录学生档案，方便家长了解和掌握孩子成长发育现状，方便学校高效管理学生心理健康工作，协助教育行政部门了解区域内学生心理健康情况，为国家制定有关政策提供依据。"
        ],
        apply: ''
      },
      {
        id: 6,
        name: '职业心理与能力测评云平台',
        imgUrl: require('../assets/images/zhiye.png'),
        version: [],
        detail: [
          '系统具有科学性和实用性的特点，选用了在人事测评领域应用非常广泛的多个经典测验，对个体的个性品质、职业适应性、组织行为特点、能力、人际特征、管理技能等方面进行了测查和评估，可以帮助企事业单位了解员工的全面素质、工作状态、心理健康程度，可有效地协助各单位开展员工招聘、干部选拔、人员调动等工作，也可更加有效的为员工进行心理调节、减压放松，创建和谐工作环境，提高工作效率。同时，系统可配置企事业单位团体辅导活动或专业EAP服务，强企业的凝聚力，减少员工问题带来的损失。'
        ],
        apply: ''
      },
      {
        id: 3,
        name: "儿童多元智能评估与教育指导云平台", //产品名称
        imgUrl: require("../assets/images/ertongduoyuan.png"), //产品图片
        version: [], //版本
        detail: [
          "系统基于美国哈佛大学心理学家加德纳博士在 1983 年提出的多元智能理论，每个人都拥有不同的智能优势组合。通过专业量表评估儿童语言智能、数学逻辑智能、空间智能、身体运动智能、音乐智能、人际智能、自我认知智能、自然认知智能八大智能表现，找到儿童的优劣势发展，并为其提供个性化的教育指导，协助促进儿童的健康发展。",
        ], //产品详情描述
        apply:
          "高校儿童科研机构、儿童教育机构、儿童保健科、社区、各级健康体检中心等。", //适用于
      },
      {
        id: 4,
        name: "儿童综合素质评估与发展云平台", //产品名称
        imgUrl: require("../assets/images/ertongzonghe.png"), //产品图片
        version: [], //版本
        detail: [
          "系统为一站式高精密体格检测与综合素质测评，可提供儿童身高、体重等体格发育测评，也可提供儿童情绪、行为、智力、注意力、气质、心理健康等方面的综合素质测评，形成儿童全面的生长发育发展档案，为儿童健康成长提供评价与指导方案。",
        ], //产品详情描述
        apply:
          "高校儿童科研机构、儿童教育机构、儿童保健科、儿童福利机构、社区、康复中心及各级健康体检中心、疾病预防控制中心等。", //适用于
      },
      {
        id: 1,
        name: "在线心理测评应用云平台", //产品名称
        imgUrl: require("../assets/images/zaixianxinlicep.png"), //产品图片
        version: ["幼儿版", "中小学版", "大学版", "司法版", "体育专项"], //版本
        detail: [
          "系统响应国家“十四五”国民健康规划和健康中国行动，遵循预防为主、监测预警、及时响应、重在教育的基本原则，是一个数字化的互联网心理服务平台。",
          "三级评估，逐级聚焦，准确筛查目标群体。第一步：一般健康量表，评估主客观存在或影响" +
          "心理健康的因素；第二步：自杀或自伤行为评估，针对心理健康不佳的人员继续进行危险行为筛查，评估其发生危险行为的倾向；第三步：针对高危人员进行专业深度了解与干预。",
        ], //产品详情描述
        apply: "", //适用于
      },
      {
        id: 2,
        name: "孤独症谱系筛查与评估云平台", //产品名称
        imgUrl: require("../assets/images/gudu.png"), //产品图片
        version: [], //版本
        detail: [
          "系统将情感计算技术与传统量表筛查相结合，以客观数据和主观数据为依据，采用“评估-反馈-干预-再评估”的方式，帮助学生或教师开展孤独症研究或评估，并能做出针对性干预训练。",
        ], //产品详情描述
        apply: "", //适用于
      },
      {
        id: 7,
        name: "实验室管理云平台", //产品名称
        imgUrl: require("../assets/images/shiyanshi.png"), //产品图片
        version: [], //版本
        detail: [
          "心理学智慧实验室管理系统整个平台对实验教学中心的门户信息集成、信息交互、实践教学、设备耗材、实验资源、成果汇报等全方位信息化管理。整个系统平台涵盖了实验室门户网站、实验室管理（人、财、物）、实验预约、实践教学管理（在线实验、公开课件等）、资产设备、耗材管理、实验室计算机管理监控、报表统计、数据上报等功能，支持门禁集成等，实现实验教学中心、多实验室一体化管理。",
          "系统设计过程中，充分考虑了高校实验室管理的难点和重点：对实验室成果的管理。通过审批流程的创新，积极鼓励广大师生上报实验室使用成果，并进行统计、分析，生成相应报告。",
          "实验室管理系统，通过对“人”、“财”、“物”的全方位管理，真正实现实验室管理的智能化、统一化，规范实验室管理，减轻工作人员负担。",
          "通过自定义“静态内容”（包括网站栏目、界面风格、模块结构等）和“动态内容”（包括预约过程、审批流程、权限角色等），快速建立校本特色的门户网站。",
          "系统支持实验室成果管理，并且自动督促用户，及时提交研究成果，便于实验室成果管理和数据统计，实验室管理过程实现真正的“闭环”化。"
        ], //产品详情描述
        apply: "", //适用于
      },
      {
        id: 8,
        name: '心理测评小程序',
        imgUrl: require("../assets/images/xcx.jpg"),
        version: [], //版本
        apply: "", //适用于
        detail: [

        ]
      }
    ],
    // 认知训练 ---类型列表
    products_2: [
      {
        id: 7,
        name: "认知能力测试与训练系统", //产品名称
        imgUrl: require("../assets/images/renzhinengliceshi.png"), //产品图片
        //产品详情描述
        detail: [
          "根据认知发展规律确定测验与训练内容，用以评价与提高用户认知能力的多功能系统，包括认知评估和认知训练两个功能模块。如常见的皮博迪图片词汇测验PPVT5、瑞文标准推理测验 SPM、神经行为认知状况测试 NCSE、洛文斯顿认知功能评定测验 LOTCA、行为忽略测验 BIT。",
        ],
        version: [], //版本
        apply:
          "记忆、注意力、反应速度、理解力、灵活性等领域研究，老年性痴呆（认知障碍）、学习障碍、ADD/ADHD等应用领域研究",
      },
      {
        id: 26,
        name: "VR 智能认知评估训练系统", //产品名称
        imgUrl: require("../assets/images/VRzhineng.png"), //产品图片
        //产品详情描述
        detail: [
          "基于认知心理学的理论基础，结合虚拟现实技术、区块链技术和人工智能技术，运用多款趣味十足的训练项目，通过在元宇由中闯关升级的方式，分别从反应力、注意力、记忆力、运动能力、空间能力、信息处理能力、解决问题能力、视觉认知能力等多个维度进行测评与训练，有助于提升大脑的综合能力。",
        ],
        apply: "",
        version: [], //版本
      },
      {
        id: 8,
        name: "注意力训练评估系统", //产品名称
        imgUrl: require("../assets/images/other.jpg"), //产品图片
        //产品详情描述
        detail: [
          "该系统包含评估和训练两方面，专业评估量表，更好地对患者进行测评，不同的训练模式和注意力游戏，更好提高注意力。",
        ],
        apply: "注意力发展课题研究，ADD/ADHD、学习困难等应用领域研究",
        version: [], //版本
      },
      {
        id: 23,
        name: "孤独症儿童个别化教育计划训练评估系统", //产品名称
        imgUrl: require("../assets/images/guduzhnegertonggebiejioayu.png"), //产品图片
        //产品详情描述
        detail: [
          "系统对孤独症儿童的发展现状及需要进行评估，并为孤独症儿童的教育康复提供训练功能。将评估与教育康复训练相结合，在充分分析他们的发展水平、优势和不足的基础上，制定针对性的训练目标和训练计划，并对训练的效果进行动态的评估，以便及时调整训练的目标和计划，以保证孤独症教育康复训练的科学性和有效性。（使用中残联认定的孤独症量表评估）",
        ],
        apply: "孤独症教育课题研究",
        version: [], //版本
      },
      {
        id: 22,
        name: "自闭与多动障碍干预系统", //产品名称
        imgUrl: require("../assets/images/zibiduodongzhnagai.png"), //产品图片
        //产品详情描述
        detail: [
          "针对儿童的注意力缺陷、语言发育迟、语言沟通障碍、情绪行为障碍等问题进行实时干预，整合脑神经系统的运动，影响其思考方式及行为方式。（重点是注意力和语言沟通能力的训练）",
        ],
        apply: "自闭症和多动症课题研究",
        version: [], //版本
      },
      {
        id: 21,
        name: "儿童自闭症谱系障碍训练评估系统", //产品名称
        imgUrl: require("../assets/images/ertongzibizhnegpuxizhnagai.png"), //产品图片
        //产品详情描述
        detail: [
          "系统对自闭症谱系儿童的发展现状及需要进行评估，并为自闭症谱系儿童的教育康复提供训练功能。除自闭症外，还包括阿斯伯格综合征、非典型的自闭症等自闭症谱系障碍的评估与训练。如常见的自闭症儿童发展评估量表（中残联），自闭症儿童心理教育量表第三版（PEP3），自闭症行为量表（ABC）。",
        ],
        apply: "自闭症谱系障碍问题评估。",
        version: [], //版本
      },
      {
        id: 9,
        name: "儿童智力训练评估系统", //产品名称
        imgUrl: require("../assets/images/ertongzhilixunlian.png"), //产品图片
        //产品详情描述
        detail: [
          "通过评估儿童各方面智力发展状况，对儿童进行训练。系统包括智力评估量表、启发智力音乐、游戏和动画视频，促进儿童思维力，注意力和记忆力。",
        ],
        apply: "智力发展课题研究，ADD/ADHD、学习困难等应用领域研究",
        version: [], //版本
      },
      {
        id: 19,
        name: "情绪与行为干预系统", //产品名称
        imgUrl: require("../assets/images/qingxuyuxingweiganyu.png"), //产品图片
        //产品详情描述
        detail: [
          "运用心理学原理，利用多媒体声光实时反馈技术，通过富有感染力、吸引力的视听形象，对情绪行为进行实时干预，使其接受现实、恢复功能、克服障碍，从而消除或缓解情绪行为障碍帮助激发潜能,掌握一定生活交往技能,重新回归社会。",
        ],
        apply:
          " 情绪障碍、行为障碍、注意力缺陷、自闭症、多动症等应用领域研究。",
        version: [], //版本
      },
      {
        id: 20,
        name: "可视音乐干预系统", //产品名称
        imgUrl: require("../assets/images/keshihuaMusic.png"), //产品图片
        //产品详情描述
        detail: [
          "运用心理学原理，利用多媒体声光实时反馈技术，通过富有感染力、吸引力的音乐视听形象，对情绪与行为问题进行实时干预与治疗，从而消除或缓解情绪行为障碍。可视化音乐干预系统集音乐、医学、心理、物理等多门学科的新兴技术于一体，是跨学科应用的典型代表。 干预内容涵盖肯定、中性及否定，由抽象到具体，认知、诱导、纠正等各种干预模式。",
        ],
        apply: " 自闭症、多动症。",
        version: ["标准版", "高级版（左右眼独立干预，带环境氛围烘托灯）"], //版本
      },
      {
        id: 11,
        name: "辅助沟通训练系统", //产品名称
        imgUrl: require("../assets/images/fuzhugoutong.png"), //产品图片
        //产品详情描述
        detail: [
          "通过日常物品、动作情绪、音效声音等常见的环境事物，训练看图发音、指图沟通，声音识别等沟通能力，提升自理技能，提高与外界沟通交流的欲望。",
        ],
        apply: "语言交流障碍的研究与应用",
        version: [], //版本
      },
      {
        id: 12,
        name: "主题教育系统", //产品名称
        imgUrl: require("../assets/images/zhutijiaoyu.png"), //产品图片
        //产品详情描述
        detail: [
          "用于培养语句理解和表达能力，培养主动交流意识，提高口语交流水平，促进思维发展的教育系统。为使用者叙述和交流创造正式贴切的情景，为教育者提供丰富的故事教育资源。",
        ],
        apply: "语言发育障碍、认知障碍等研究与应用",
        version: [], //版本
      },
      {
        id: 13,
        name: "早期语言评估与干预系统", //产品名称
        imgUrl: require("../assets/images/zaoqiyuyanganyu.png"), //产品图片
        //产品详情描述
        detail: [
          "通过选取核心字、词语、词组、句子、对话、短文，采用循序渐进的方式，早期语言、认知、理解、表达、韵律能力进行评估和训练，并帮助语言治疗师定制康复方案和监控康复效果。（针对幼儿）",
        ],
        apply: "幼儿早期语言研究",
        version: [], //版本
      },
      {
        id: 18,
        name: "构音测量与训练系统", //产品名称
        imgUrl: require("../assets/images/gouyincelaing.png"), //产品图片
        //产品详情描述
        detail: [
          "从语音角度和运动角度对受测者的构音功能进行科学的评价，从而诊断构音障碍的生理和语音水平的双纬度原因。并能够针对性地提出个别化构音训练手段。",
        ],
        apply: "构音障碍问题研究",
        version: [], //版本
      },
      {
        id: 16,
        name: "语音评估与训练系统", //产品名称
        imgUrl: require("../assets/images/yuyinpinguyuxunlian.jpg"), //产品图片
        //产品详情描述
        detail: [
          "通过智能语音分析引擎，对语音特征，发音嘴形等特征进行采集识别，分析出受测者的发音特征，从而进行针对性发音和口形训练。",
        ],
        apply: "语音课题研究，言语发育障碍、自闭症等应用领域研究",
        version: [], //版本
      },
      {
        id: 15,
        name: "言语障碍测量系统", //产品名称
        imgUrl: require("../assets/images/yanyuzhangaiceliang.jpg"), //产品图片
        //产品详情描述
        detail: [
          "利用数字信号处理技术和实时反馈技术对言语功能进行定量评估和实时训练的现代化言语治疗设备。可依据“言语功能评估标准”对言语的呼吸、发声、共鸣功能进行评估并制定合理的矫治方案。",
        ],
        apply:
          "言语功能评估，沟通表达困难、自闭症、听力障碍等应用领域研究。（儿童）",
        version: [], //版本
      },
      {
        id: 14,
        name: "言语矫治系统", //产品名称
        imgUrl: require("../assets/images/yanyujioazhi.png"), //产品图片
        //产品详情描述
        detail: [
          "融实时治疗与视听反馈技术为一体的言语矫治系统，为各类言语异常的矫治提供了有效的手段。用户在发音后能立刻获得动画形式的视觉反馈，可以提高治疗效果。",
        ],
        apply: " 言语发育障碍研究课题。( 儿童 )",
        version: [], //版本
      },
      {
        id: 17,
        name: "语言康复训练系统", //产品名称
        imgUrl: require("../assets/images/yuyankangfu.png"), //产品图片
        //产品详情描述
        detail: [
          "通过综合计算机科学、语言学、心理学、听觉学、统计学、病理学、解剖学和生理学等多种学科，以生动有趣的游戏和练习，达到训练语言功能的目的。",
        ],
        apply: "语言障碍课题研究。（儿童）",
        version: [], //版本
      },
      {
        id: 1,
        name: "听觉统合训练系统", //产品名称
        imgUrl: require("../assets/images/tingjuetonghexitong.png"), //产品图片
        version: ["标准版", "高级版（1对多人）"], //版本
        apply:
          "听觉领域课题研究，学习障碍（读写障碍）、多动症（注意力缺陷）、孤独症（自闭症）、言语语言发育障碍、情绪障碍等应用领域研究",
        //产品详情描述
        detail: [
          "通过听觉专项训练，矫正听觉系统对声音处理失调的现象，从而达到改善语言障碍、交往障碍、情绪失调和行为紊乱的目的。",
          "有标准版和高级版两种：高级版可实现一对多，即多人同时差异性使用，比多台标准本更有优势",
        ],
      },
      {
        id: 2,
        name: "视听统合训练系统", //产品名称
        imgUrl: require("../assets/images/shitingtonghe.png"), //产品图片
        version: ["标准版", "高级版（带环境氛围烘托灯）"], //版本
        //适用于
        apply:
          "听觉领域课题研究，学习障碍（读写障碍）、多动症（注意力缺陷）、孤独症（自闭症）、言语语言发育障碍、情绪障碍等应用领域研究",
        detail: [
          "通过听觉和视觉通道同时专项训练，矫正感觉系统对声音处理失调的现象，从而达到改善语言障碍、交往障碍、情绪失调和行为紊乱的目的。",
          "与听觉统合训练系统的区别，增加了视觉通道刺激，且内容不同",
        ],
      },
      {
        id: 3,
        name: "听觉评估导航系统", //产品名称
        imgUrl: require("../assets/images/tingjuepingudaohang.png"), //产品图片
        apply: "听觉领域课题研究、听力障碍评估",
        //产品详情描述
        detail: [
          "通过数量和功能两个方面评估受测者对声音察知、声音分辨、声音辨识、声音理解的能力。",
        ],
        version: [], //版本
      },
      {
        id: 4,
        name: "听觉康复训练系统", //产品名称
        imgUrl: require("../assets/images/tingjuekangfu.png"), //产品图片
        apply: "听觉领域课题研究、听觉障碍康复与训练",
        //产品详情描述
        detail: [
          "融康复和训练为一体的听觉言语学习系统，将丰富的视觉和听觉材料有机集合，强化听觉恢复。",
        ],
        version: [], //版本
      },
      {
        id: 5,
        name: "平衡训练评估系统", //产品名称
        imgUrl: require("../assets/images/pinghenglipingu.png"), //产品图片
        apply: "平衡感发展课题研究",
        //产品详情描述
        detail: [
          "通过躯体平衡感应装置，结合4点压力感应装置，综合感知躯体各个部位的平衡状况。",
        ],
        version: [], //版本
      },
      {
        id: 6,
        name: "虚拟情境互动训练与评估系统", //产品名称
        imgUrl: require("../assets/images/xunichangjinghudong.png"), //产品图片
        //产品详情描述
        detail: [
          "通过躯体感应装置，对人体姿势、动作、位置等信息进行采集、分析、识别等处理，在一个完全虚拟的动画环境里，提供非常有趣的互动体验，进行肢体训练和感觉统合的。",
        ],
        apply: "儿童肢体训练与感觉统合发展课题研究",
        version: [], //版本
      },
      {
        id: 10,
        name: "数字OT评估与训练系统", //产品名称
        imgUrl: require("../assets/images/chengrenshuziot.png"), //产品图片
        //产品详情描述
        detail: [
          "融合最新的虚拟现实技术和人机情景互动技术，集认知训练、作业治疗、运动康复、教学评估、学习娱乐于一体的智能平台。",
        ],
        apply: "轻度认知障碍、肢体功能/协调障碍等应用领域研究",
        version: [], //版本
      },
    ],
    // 心理咨询 ---类型列表
    products_3: [
      {
        id: 13,
        name: '多人 EEG脑波对抗训练系统',
        imgUrl: require('../assets/images/duoreneegnaoboduikangxunlianxitong.png'),
        version: [],
        apply: '',
        detail: [
          "【产品简介】：",
          "EEG脑波训练对抗系统采用高效精准的生物反馈技术通过心理对抗训练达到帮助使用者抗干扰以及提升专注度的目的。",

          "【软件参数】：",
          "具备三款2-4人的脑波对抗训练科目。",

          "脑波赛车：2或者4人分为两组第一视角进行模拟F1赛车的脑波对抗项目，能够通过注意力强度决定被控制的车辆速度从而决定排名。",

          "脑波赛跑：2或者4人分为一组模拟田径赛跑比赛，通过佩戴脑波灯发带采集用户专注度，从而控制训练科目中虚拟人员的跑步速度，进行趣味性的脑波专注度对抗。",

          "脑波对波：团队型的专注力比拼训练项目，通过2人组队，形成2组人员进行专注力控制能力的比拼。",
          "【硬件配置】：",
          "1：55寸超清显示屏1 台",
          "2：脑波发带 4 套（佩戴微型脑波灯）"
        ]
      },
      {
        id: 12,
        name: '心理健康自助系统（EEG生物反馈对抗版）',
        imgUrl: require('../assets/images/xinlijiankangzizhu.png'),
        version: [],
        apply: '',
        detail: [
          "1、32-55寸（配置可选）寸液晶宽屏显示器，机柜高度113cm",
          "2、电容触摸屏：与其他厂家不同的是，我公司的心理自助仪采用了比电阻屏更好使用的电容屏。",
          "3、机柜外观：全钢材料、高档烤漆、防锈、防水、防腐蚀、耐磨、防磁、 防静电。",
          "4、主控模块：控制面板、01开关、ATX开关、RJ45标准网络接口等。",
          "5、扬声器：多媒体立体声、双声道、防磁音响。",
          "6、电源模块：双路供电，双路在线电源，主机模块与功能模块独立供电。",
          "7、功耗：工作功耗：500W—700W，待机功耗：40W—50W",
          "8、冷轧钢板结构1.2-1.5mm；表面金属烤漆；颜色可定制；50W功放音箱系统。",
          "9、每日定时关机，需要时一键开机并自动运行自助服务系统。",
          "系统主要功能",
          "系统自带会员中心，心理游戏，心理测试，心理教师，心理百科，心理课堂，心理教案，心理悦读，心理预约，心理影院，心理音乐，注意力训练12大功能模块。",
          "会员中心：包含测评管理：支持查看测评报告，查看预警列表和待测量表清单，心理应用：包含专业心理图片，心理故事，心理效应，心理实验，放松助手和涂鸦心理。支持修改和编辑个人详细资料，退出系统和关闭系统。",
          "心理游戏：中包含苹果三角奶酪饼，怒打富翁，可爱宝宝找星星，愤怒的小鸟弹跳球，海绵宝宝爱糖果，独自为伴等多种心理游戏。增强系统趣味性，可以有效提升使用者注意力、放松度、记忆力等各方面的能力。",
          "心理音乐：包含常见多种类心理音乐，能促进自闭症儿童的语言发展，自我表达障碍，具有明显的促进作用。",
          "心理测试：包含7大类常见心理测试量表，其中常见的有心理健康临床症状自评量表", "（SCL-90）,学习焦虑倾向测量，抑郁自评量表，思维能力测试，创造能力测试等，管理员可通过登录管理员账号查看用户的测试结果，不会对测试者造成心理方面的影响，测试结果可word存档或直接打印。",
          "注意力训练：中有舒尔特测试，注意力及视觉反应训练，注意力及运动反应训练，注意力集中训练。可以简单测量注意力水平和是否有注意力方面的困扰，评估注意力的各种细节能力。",
          "最强大脑（脑力对抗）：运用脑波采集EEG数据进行双人脑力对抗的心理训练，通过无线采集方案将用户的专注度与放松度实时同步至系统显示屏上，双方依据专注度的强度决定训练的最终获胜者。",
          "心理预约：可了解单位心理咨询师基本资料和个人简介，可预约您了解的心理咨询师，简单描述个人想咨询的问题和联系方式，管理员可根据用户留下的预约信息进行及时回复。"
        ]
      },
      {
        id: 11,
        name: "VR心理运动减压系统", //产品名称
        imgUrl: require("../assets/images/VRxinliyundongjianya.png"), //产品图片
        version: [], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "主要骑行功能：减压骑行、情绪宣泄、心理训练、趣味骑行",
          "辅助插件：",
          "(1)数据面板：具备虚拟数据显示面板，能够实时显示用户速度、心率、骑行时间、里程、卡路里、阻力6项骑行数据。",
          "(2)生物反馈：无需配套任何生物采集配件即可采集用户心率数据。",
          "(3)天气控制：用户在骑行时能够自行控制下雨、刮风、天晴、下雪等骑行环境。",
          "(4)智能阻力：系统根据场景模型自动匹配相对应的骑行阻力，模拟真实骑行环境（约1秒延迟）",
        ],
      },
      {
        id: 1,
        name: "心理健康自助系统", //产品名称
        imgUrl: require("../assets/images/xinlijiankangzizhuxitong.png"), //产品图片
        version: ["22寸", "32寸", "42寸", "55寸"], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "主要作用于心理健康中心宣教的心理健康中心服务设备，通过视频、音频、文字等内容展示推广心理学领域知识展示中心风采。系统主要由趣味测评、心理图片、调节训练、心理电台、心理音乐、心理绘画、视频心理宣教、心理电影、机构介绍、预约咨询、专家介绍、功能室介绍、心理游戏、系统设置。",
        ],
      },
      {
        id: 2,
        name: "身心减压放松系统", //产品名称
        imgUrl: require("../assets/images/shenxinjianyafangsongxitong.png"), //产品图片
        version: ["非反馈版", "反馈版"], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "运用临床生物反馈医学技术开发的一款集压力调节系统，运用专业的心理压力量表与HRV数据评估心理状态，并且通过生物反馈技术进行身心调试，帮助使用者习得快速缓解压力的能力，提高抗压能力与情绪的稳定性。",
        ],
      },
      {
        id: 3,
        name: "智能呐喊宣泄系统", //产品名称
        imgUrl: require("../assets/images/zhinengnahanxuanxie.png"), //产品图片
        version: [], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "智能呐喊宣泄仪是根据微处理开发的以“呐喊”为主题的新型智能化、自助式情绪宣泄设备。不需要咨询师引导，来访者可以选择适合当前情绪的宣泄主题，自主、自助式来发泄其焦虑、紧张、自卑等负面情绪，同时在正向引导语的疏导下提升自己调节情绪、管理情绪的能力。",
        ],
      },
      {
        id: 4,
        name: "智能互动宣泄仪", //产品名称
        imgUrl: require("../assets/images/zhinenghudongxuanxie.png"), //产品图片
        version: [], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "利用游戏的方式，依靠传感器捕捉三维空间中玩家的运动，不需要任何的辅助器材，控制游戏角色进行游戏训练，达到身心平衡训练的效果。",
        ],
      },
      {
        id: 5,
        name: "智能击打宣泄系统", //产品名称
        imgUrl: require("../assets/images/zhinengjidaxuanxie.png"), //产品图片
        version: [], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "8大宣泄主题选择：提供了疏导、缓解、克服、调整、释放、消除、战胜、脱离等8种常见的情绪问题而设定的宣泄主题，触摸按键，可根据实际情况任意触摸选择宣泄主题，操作界面高度集中。选择主题后系统将自动播放一段引导语音，可以通过双击主题按键跳过引导语音，直接进行宣泄。",
        ],
      },
      {
        id: 6,
        name: "智能击打情绪调节系统", //产品名称
        imgUrl: require("../assets/images/zhinengjidaqingxutiaojie.png"), //产品图片
        version: [], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "该系统基于“人机互动”概念设计，具有击打、呐喊、自信引导三模式功能，支持一键切换。",
          "系统提供多主题训练方案和多款互动训练游戏，通过采集训练者击打力度、呐喊分贝值以及持续时间智能匹配互动指导语音，以新颖独特的游戏方式完成情绪调节，消除焦虑、紧张、冲动、抑郁等负面情绪，达到调节身心、减缓压力、疏解情绪的宣泄效果。",
        ],
      },
      {
        id: 7,
        name: "室内团体活动道具包", //产品名称
        imgUrl: require("../assets/images/shineituantihuodongdaojubao.png"), //产品图片
        version: [], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "包括环境适应篇、沟通交往篇、竞争合作篇、创新实践篇、自我意识篇、学习管理篇、意志责任篇、心灵成长篇等共8大主题41种游戏，有效帮助开展各种形式的心理训练活动。",
        ],
      },
      {
        id: 8,
        name: "室外团体扩展训练器材包", //产品名称
        imgUrl: require("../assets/images/shiwaituantikuozhanxunlian.png"), //产品图片
        version: [], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "该套设备由有轨电车，穿越电网，突破雷阵，相依为命等13款游戏组成",
          "表达性艺术心理辅导工具包",
          "表达性艺术心理辅导工具包基于艺术疗法、音乐疗法、舞蹈疗法、绘画疗法等专业的心理学原理，根据心理学家丰富的心理辅导和治疗经验，精心设计研发，创制而成",
        ],
      },
      {
        id: 9,
        name: "校园心理情景剧器材箱", //产品名称
        imgUrl: require("../assets/images/xiaoyuanxinliqingjingjuqicaixiang.png"), //产品图片
        version: [], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "心理剧道具箱是以美国最新的心理剧理论（螺旋心理剧，简称TSM）为依据，以国内心理学专家的实际经验为指导而研发而成的。不同于传统心理剧，TSM更加注重“主角”力量的建设，避免对“主角”造成二次创伤，因而道具箱在设计的时候也注重增加力量构建的元素。在听取了有关专家的意见之后增添了促进快速放松的元素，使得道具箱不仅实际应用性强，而且具有新奇、增强兴趣等特点。每年邀请国际权威培训师进行现场教学培训，演示心理剧导剧的过程以及相关道具的使用，为心理剧道具箱客户提供无限提升的平台。",
        ],
      },
      {
        id: 10,
        name: "心理沙盘游戏", //产品名称
        imgUrl: require("../assets/images/xinlishapanyouxi.png"), //产品图片
        version: [], //版本
        // 适用于
        apply: "",
        //产品详情描述
        detail: [
          "沙盘游戏被广泛地运用在了心理咨询、心理评估、心理治疗、心理教育、人力资源开发和EAP，以及专业心理分析的诸多领域；尤其是沙盘游戏与学校心理教育的结合，在我们国内获得了重要的发展。",
        ],
      },
    ],
    // 心理科研 ---类型列表
    products_4: [
      {
        id: 1,
        name: '面部表情分析系统',
        imgUrl: require('../assets/images/face.png'),
        version: [],
        apply: '',
        detail: [
          "【产品简介】：",
          "面部表情分析系统（Xface)利用先进的计算机视觉和深度学习技术，自动识别和分析用户的面部表情。通过捕捉面部的微妙变化，系统能够解读受测者的情绪状态，为用户提供关于情感反应的深度洞察。可实现多模态数据采集，即可同时与脑电、眼动、近红外、眼动同时记录数据，并可同步打标。",
        ]
      },
      {
        id: 2,
        name: '行为观察记录分析系统',
        imgUrl: require('../assets/images/xingwei.jpg'),
        version: [],
        apply: '',
        detail: [
          "【产品简介】：",
          "行为观察分析系统(Xbehavior)基于计算机视觉、深度学习和模式识别等技术，利用动作识别Al算法，实现对人体姿态、动作和行为的自动化识别与分析，帮助研究者了解个体或团体在自然环境或特定环境下的反应或互动，从而推断出个体或团体行为背后的规律，通过行为进一步探究个体的态度、情感、动机等心理发展的本质。",
        ]
      },
      {
        id: 3,
        name: '脑电',
        imgUrl: require('../assets/images/naodian.png'),
        version: [],
        apply: '',
        detail: [
          "【产品简介】：",
          "事件相关电位技术是一种使用电生理指标记录大脑活动的方法，大脑在活动时，大量神经元同步发生的突触后电位经总和后形成的。它记录大脑活动时的电波变化，是脑神经细胞的电生理活动在大脑皮层或头皮表面的总体反映。通过事件相关电位可进行听觉诱发反应实验、视觉诱发反应实验、体感诱发反应实验、语言加工研究、注意研究、记忆研究、意识研究等。利用该系统对不同脑电成分的分析和研究，观察不同心理活动状态下的脑活动机制。在脑机接口方面，事件相关电位系统可以用于测量大脑对特定刺激的反应，例如对视觉、听觉或触觉刺激的反应。通过记录大脑在刺激呈现或任务执行时的电活动，事件相关电位系统能够提供关于大脑如何处理和解析这些刺激的信息。这种信息可以用于开发基于脑电信号的脑机接口，实现人与计算机或其他设备的直接通信。例如，基于视觉事件相关电位的脑机接口可以实现字符输入、命令执行等操作，为残疾人士提供新的交流方式。在康复方面，事件相关电位系统可以用于评估和治疗神经或精神障碍患者。通过对患者的大脑电活动进行测量和分析，事件相关电位系统能够提供关于患者大脑功能和认知能力的信息。这种信息可以用于制定个性化的康复计划，帮助患者恢复认知功能或提高生活质量。例如，在脑卒中患者的康复治疗中，事件相关电位系统可以用于评估患者的认知功能，并提供关于治疗方案和康复进度的反馈。",

        ]
      },
      {
        id: 4,
        name: '功能性近红外脑成像',
        imgUrl: require('../assets/images/jinhongwai.png'),
        version: [],
        apply: '',
        detail: [
          "【产品简介】：",
          "功能性近红外脑成像(functional near- infrared spectroscopy，fNIRS)主要利用多个波长的近红外光与脑组织中生色团物质之间的吸收和散射关系，考察特定状态下脑组织中氧合血红蛋白、脱氧血红蛋白以及总血红蛋白的浓度变化，进而间接考察神经元的活动、细胞能量代谢以及血液动力学相关的功能，反映大脑的状态与加工的过程。主要用于脑机接口、认知神经科学、心理学、临床医学、 教育学、语言获得、信息工学、神经管理学等方面的研究。在脑机接口方面，便携式近红外光学成像系统可以用于实时监测大脑皮层的血氧饱和度和血流动力学变化。这些数据反映了大脑的活动状态和神经信号的传递，进而可用于解码大脑意图和驱动外部设备。例如，便携式近红外光学成像系统可以用于脑机接口在运动控制、感知反馈和认知训练等方面的应用，帮助残疾人士实现自主行动和日常生活能力的提升。便携式近红外光学成像系统可以用于评估和监测康复治疗效果。通过对大脑皮层的血氧饱和度和血流动力学变化进行测量和分析，便携式近红外光学成像系统能够提供关于大脑功能和康复进展的信息。这种信息可以帮助医生制定个性化的康复计划，并监测患者的康复过程，及时调整治疗方案。例如，在脑卒中患者的康复治疗中，便携式近红外光学成像系统可以用于评估患者的大脑功能和康复效果，为患者提供更加针对性的康复治疗方案。",
        ]
      },
      {
        id: 5,
        name: '眼动',
        imgUrl: require('../assets/images/yandong.png'),
        version: ["遥测式", "眼睛式", "桌面式", "VR"],
        apply: '',
        detail: [
          "【产品简介】：",
          "眼动仪是一种记录和研究人眼运动轨迹和视觉注意力的科学仪器。它通过测量眼球的运动轨迹来研究人眼看图像或文本时的视线路径和注意力分布。",
          "眼动仪的应用范围广泛，包括心理学、人机交互、广告设计、医学、运动员技术训练等领域。通过眼动仪的记录和分析，可以了解到受试者在观看图像、读取文本或使用电脑等情境下的眼动特征，找到流行设计图案的共通点、探究电视广告的效果、分析病人的眼部疾病状态、评估运动员反应速度等等。"
        ]
      },
      {
        id: 6,
        name: '生理采集系统',
        imgUrl: require('../assets/images/shengli.png'),
        version: [],
        apply: '',
        detail: [
          "【产品简介】：",
          "生理多导系统为不同实验方案 - 从简单到复杂 -提供支持，其设计允许不同经验水平的研究人员获取、分析和解释科学数据。提供解决方案，启动设备可轻松扩展以满足开发需求。",
          "该系统为灵活、可靠的模块化数据采集和分析设备，应用于生命科学研究、人因工效学研究、心理学研究等领域。"
        ]
      },
    ]
  },

  //行业资讯的新闻列表
  newsList: [
    {
      id: 8,
      title: '我公司当选为海峡两岸心理服务行业产教融合共同体理事单位',
      time: {
        month: '03/26',
        year: '2024'
      },
      from: '',
      content: [
        "为深入学习贯彻党的二十大精神和习近平总书记关于职业教育工作的系列重要指示精神，落实中办、国办《关于深化现代职业教育体系建设改革的意见》，3月23日至24日，“海峡两岸心理服务行业产教融合共同体”预备会议、成立大会在福州举办。其中，重庆理工大学国家大学科技园在孵企业心奕心（重庆）科技有限公司当选为海峡两岸心理服务行业产教融合共同体理事单位。",
        "据悉，海峡两岸心理服务行业产教融合共同体是由海峡两岸相关本科院校、职业院校、心理服务相关行业组织、科研机构和企事业单位等组建的产教融合共同体，旨在充分发挥海峡两岸的资源优势，汇聚跨区域产教资源，不断深化海峡两岸心理健康服务领域的交流与合作，推动心理健康人才培养和行业专业化发展。",
        "心奕心（重庆）科技有限公司是一家致力于用创新科技助力心理健康教育与服务的企业。其专注于人工智能、信息技术与教育的深度结合，立足国家相关政策， 服务于数字化教育的改革。公司坚持以客户为中心，旨在为院校提供专业、科学的整体解决方案。",
        "当前国家、社会高度重视国民健康心态，心理产业发展前景需求不断增长，在孵企业心奕心（重庆）科技有限公司当选为海峡两岸心理服务行业产教融合共同体理事单位也将共建开发社会心理服务资源，融合两岸学术力量、企业与行业的力量，共研行业技术攻坚，促进心理学服务人才梯队搭建，共推社会服务应用与发展。"
      ]

    },
    {
      id: 7,
      title: '国家卫生健康委就促进儿童心理健康共同守护美好未来有关情况举行发布会（文字实录）',
      time: {
        month: '10/09',
        year: '2023'
      },
      from: '来源于健康中国 发表于北京（该内容属于转载）',
      content: [
        "国家卫生健康委于10月9日在国家卫生健康委2号楼新闻发布厅召开新闻发布会，介绍促进儿童心理健康共同守护美好未来有关情况。",
        "主持人、国家卫生健康委新闻发言人、宣传司副司长——米锋：",
        "“各位媒体朋友，大家下午好！欢迎参加国家卫生健康委例行新闻发布会。今天的发布会也是国家卫生健康委贯彻落实党的二十大精神系列新闻发布会的第九场。首先向大家通报近期出台的卫生健康重要政策文件的有关情况。",
        "一是联合科技部、工业与信息化部、国家药监局等部门制定并公布了第二批罕见病目录，进一步加强我国罕见病管理，提高罕见病诊疗水平，维护罕见病患者的健康权益。",
        "二是联合市场监管总局印发2023年第6号公告，发布85项新食品安全国家标准和3项修改单，参考国际相关法规和通行做法，更好地维护我国食品安全和消费者健康。",
        "三是发布2023年第8号公告，根据《中华人民共和国食品安全法》规定，审评机构组织专家对桃胶等15种Ⅲ型食品相关产品新品种的安全性评估材料进行审查并通过。",
        "以上是近期工作情况的通报。",
        "习近平总书记在党的二十大报告中强调，重视心理健康和精神卫生。2023年10月10日，也就是明天，是第32个“世界精神卫生日”，国家卫生健康委将今年的宣传主题确定为“促进儿童心理健康 共同守护美好未来”，呼吁全社会共同关注儿童青少年心理健康，增进健康福祉。",
        "心理健康和精神卫生是健康的重要组成部分，儿童青少年的健康成长也牵动着每一个家庭的心。加强心理健康的促进工作是儿童青少年健康成长的必然要求。近年来，心理健康和精神卫生工作已经纳入全国深化改革和社会综合治理范畴，我们设立了国家心理健康和精神卫生防治中心，开展了社会心理服务体系建设试点，探索覆盖全人群的社会心理服务模式和工作机制。相关部门、全社会正在共同努力，积极应对儿童青少年心理健康问题。",
        "今天的发布会我们请来了国家卫生健康委医政司副司长李大川先生，教育部体卫艺司副司长一级巡视员刘培俊先生，首都医科大学附属北京安定医院儿童精神医学首席专家郑毅先生，请他们共同回答大家的提问。",
        "下面，请各位记者举手提问，提问前请先通报所在的新闻机构，请提问。”",
        "新华社记者：“想请问当前我国儿童青少年的心理健康整体情况如何？以及卫生健康部门有哪些工作举措？谢谢”",
        "国家卫生健康委医政司副司长——李大川：",
        "“谢谢您的提问。心理健康是儿童青少年健康的重要组成部分，党中央、国务院高度重视，习近平总书记多次作出重要的指示和批示，为我们做好相关工作指明了方向，提供了根本遵循。国家卫生健康委近年来会同和配合有关部门开展了一系列工作，概括起来是以下几个方面。",
        "第一，加强儿童社会心理服务体系的建设，构建儿童青少年心理健康的服务网络，我们会同有关部门印发《关于加强心理健康服务的有关意见》，其中专门就做好儿童青少年心理健康作出了具体部署。同时，我们和中央政法委、教育部等部门在全国开展社会心理服务体系的试点工作。其中，明确将完善教育系统的心理服务网络，以及加强各阶段的儿童青少年心理健康教育、心理辅导和心理支持作为重点来指导试点地区探索完善心理健康体系。截止到目前，试点工作取得了积极进展，试点地区96%的村和社区，包括100%的高校和将近95%的中小学都设立了心理辅导室或者社会工作室。",
        "第二，通过强化医疗卫生系统心理健康的服务能力建设，来提升儿童青少年心理健康服务的专业化水平。这些年来，我们持续加强精神专科医院、综合医院的精神心理科建设。在人才队伍建设方面，我们持续做好精神科医师的继续教育和转岗培训，不断提高精神科医生的诊疗水平。在规范诊疗方面，我们制定出台相关的诊疗指南和技术规范，来规范医疗服务行为。",
        "第三，我们开展系列专项行动，来保障推动各项工作能够落地见效。《健康中国行动（2019-2030年）》，其中有三个专项行动方案，包括心理健康促进行动、妇幼健康促进行动和中小学生健康促进行动三个专项行动，分别从不同的角度对做好儿童青少年心理健康作出了部署。同时，从2021年起，在重大公共卫生项目里面还设置了儿童青少年心理健康促进试点项目，组织开展儿童青少年心理健康促进的社会动员、科普宣传、筛查评估等试点工作，积累经验，不断总结和推广。谢谢。”",
        "中国日报记者：“请问，引起儿童青少年心理健康问题的主要原因有哪些？对儿童青少年的成长和发展会有哪些影响？谢谢。”",
        "首都医科大学附属北京安定医院儿童精神医学首席专家——郑毅：",
        "“谢谢你的提问，这是一个很好的问题。儿童青少年处于心理健康快速发展时期，很多内因外因都会引起儿童心理方面的问题。概括起来讲，生物、心理、社会、环境这些因素都会对儿童的心理健康产生影响。",
        "内因主要包括在儿童心理发展过程中的一些认知、情绪和行为方面的发展，可能出现了偏差，他对一些适应就会产生困难，出现严重的适应方面的问题，比如说他对认知方面容易出现认知扭曲，情绪方面容易出现情绪不稳定，抗挫能力比较差，心理韧性不足，这些都跟他的生长发展过程形成的问题有关。",
        "外部因素主要是家庭、学校、社会这些相关的环境方面造成的压力，家庭的因素大家都比较熟悉，像父母的教育方式，还有早期教育的适度程度，比如说过分偏向于知识文化，忽视了能力，忽视了适应机会，忽视了他交往能力的培养，这样的孩子大了以后心理都会出现一些问题。还有家里忽视型、溺爱型、矛盾型教育，都会或多或少对孩子的心理发展带来不同程度的影响。",
        "学校大家都很清楚了，学校应该是以学习为主，所以学习压力，还有学校的同学关系，这些竞争都会给孩子带来一些压力。但是压力和他的承受力是相辅相成的，有的时候很大的压力，可能有些孩子承受能力很强，不成为压力，有的孩子承受能力弱，就会形成很大压力。这些环境变化对孩子来说是影响因素。",
        "还有社会环境，比如现在网络的管理，孩子游戏的使用，还有一些跟社会交往的机会，比如有些区域社会风气方面的问题，这些都会给孩子带来潜移默化的影响。",
        "所以，儿童青少年心理健康问题的影响是多方面的，也可以说是内外因的综合影响。儿童心理健康的问题，它的影响是非常大的，如果不及时调整和控制，很多儿童时期精神心理方面的障碍会影响终身，最简单的是首先影响他的社会功能、他的学习，他的学习成绩可能会受到影响，甚至出现厌学拒学，有的孩子智力很好，但是就是不敢上学、不去上学，严重的逆反，有的情绪出现严重波动，要么是消极，要么是情绪暴躁，这种情绪变化也是很常见的影响。还有一些是行为方面，有攻击行为或者有自残行为，还有一些逃避学校，到社会上和坏孩子为伍，或多或少都会出现这些行为的异常。",
        "因为孩子是在成长发育的过程中，精神障碍不像一个简单的感冒发烧，病毒感染，对抗了它就消失了。心理健康的问题如果不及时诊断治疗好的话会影响终身，也就是说全生命周期的发展轨迹受到了影响。以前可能会是一个比较成功的孩子，家里环境各方面智商水平都很高，但是由于心理障碍可能就会厌学拒学，不能很好完成学业，不能完成学业以后整个生活都会出现问题。还有一些儿童时期的问题也会发展到成年，比如说注意缺陷多动障碍，这些问题也会导致成年时候严重的问题。所以成人当中也有大量儿童时期心理健康的问题。所以我觉得这是一个影响终身的大的问题，早期干预是非常关键的。谢谢。”",
        "中国教育电视台记者：“想请问教育部门，近期对于学生心理健康工作做了哪些部署？工作的重点又在哪些方面呢？谢谢。”",
        "教育部体卫艺司副司长一级巡视员——刘培俊：",
        "“感谢您关心学生的身心健康，特别是关注最近关于学生心理健康的政策部署，党中央、国务院长期以来始终高度重视学生的身心健康，对于促进学生健康作出了一系列战略部署，各级教育部门和学校全面贯彻党的教育方针，深入落实立德树人的根本任务，把心理健康作为学生全面发展的重要基础，开展了一系列大量扎实有效的工作。为进一步全方位高质量的促进学生身心健康，今年4月，教育部、国家卫生健康委等17个部门联合印发了《全面加强和改进新时代学生心理健康工作专项行动计划（2023—2025年）》，标志着加强学生心理健康工作上升为一个国家战略，摆在更加突出、更加重要的位置，并对学生心理健康工作作出了全面部署，重点在以下几个方面。",
        "第一，明确了学生心理健康工作的总体思路。强调以促进学生全面发展、健康成长为总体目标，要培育学生珍视生命、热爱生活、自尊自信、理性平和和乐观向上的心理品质。同时，通过系列的育人活动，造就学生百折不挠、坚韧不拔、宠辱不惊的意志品质，让学生的思想道德素质、科学文化素质和身心健康素质协调发展，培养担当民族复兴大任的一代新人。",
        "第二，明确了学生心理健康工作的基本原则。强调三个方面：一是坚持目标引领，全面发展、健康第一。把促进身心健康作为学生全面发展的基础前提，作为一条主线贯穿于学校德智体美劳各个方面，融入教育教学、管理服务的各个环节，家庭、学校、社会的各个环境，为学生的身心健康营造良好的条件。二是坚持问题导向，防控结合，教育在先。强调加强学生的心理健康教育，引导学生形成健康心理素质和意志品质，为学生营造良好的育人生态。三是坚持需求驱动，系统治理，能力为主。动员政府、社会、学校、家庭、医院多方面的力量，共同回应学生的心理需求，提升健康促进的能力。",
        "第三，明确学生心理健康工作的促进体系。一是坚持德智体美劳“五育”并举，将心理健康工作融入五育，高质量实施以德育心、以智慧心、以体强心、以美韧心、以劳健心的各项育人的活动。同时，坚持监测预警、健康教育、咨询服务、干预处置“四位一体”的工作体系，全方位保障学生心理健康。",
        "第四，明确学生心理健康工作的行动举措。从五育并举、监测预警、健康教育、咨询服务、干预处置、人才队伍、健康科研、健康环境等八个方面系统部署学生心理健康工作。",
        "第五，明确了各级政府教育卫健等17个部门加强学生心理健康工作的职责，强调多部门联合、医教体融合、家校社协同，共同促进学生心理健康，同时把学生心理健康工作纳入省级人民政府履行教育职责的评价，作为学校办学水平评估和领导班子年度考核的一项重要指标，根据需要加大经费支持，同时也鼓励各地、各校创新工作举措，发挥引领示范作用。",
        "总之，17部门联合印发专项行动计划，可以说是一个多部门的综合性政策文件，也是一个务实性的行动方案，目的是推动各项工作有序开展，为学生的身心健康营造更好的环境。谢谢。”",
        "中国青年报记者：“请问，家长该如何注意和观察孩子是否有心理健康问题？青春期叛逆的表现是否属于心理问题呢？谢谢。”",
        "首都医科大学附属北京安定医院儿童精神医学首席专家——郑毅：",
        "“这是一个大家都非常关心的问题。其实孩子跟家长接触是最多的，首先家长应该像关心躯体健康一样来关注心理健康，这是非常关键的，要有这个关注的意识。所以，在日常生活当中要注意孩子情绪、行为上潜移默化的变化，比如睡眠习惯改变，睡不着觉了，失眠、早醒、睡眠过多，或者进食出了问题，食欲特别不好，心情很不好，这些变化要注意敏感观察。还有一些孩子的行为出现异常变化的时候，我觉得要特别关注。",
        "我经常会讲，如果说你要觉得一个孩子情绪和行为发生了与他年龄不相符的情况，跟他的受教育水平相抵触，发生明显变化的时候，可能是一种心理方面问题的表现。",
        "第二个指标是如果这个孩子的情绪和行为给他自身或者给他周围人带来了持续的麻烦和痛苦，这个问题也不是简单的情绪和行为问题，这都可能是心理障碍的一些早期表现。所以家长要注意这些细节的变化，敏感地对孩子心理健康像躯体、疾病一样关注，能注意他的变化，要重视他可能是心理健康的问题。",
        "这些细节综合观察以后，就很容易发现他的问题。就跟现在躯体疾病，孩子发烧了，孩子肚子疼了，出现严重的问题，很少有家长不关心的，都要去送医院，都要去问问，都要去看一看，轻的处理一下，重的要去医院看看。所以，家长一定要有这种心理健康的意识，这是非常关键的。",
        "第二个是青春期的问题，这应该算是正常的心理和生理发展的过程，青春期的特点就是比较不稳定，思维活跃，但是考虑问题不全面，情绪容易变化，容易出现行为冲动，他们追求创新。这些逆反的表现让人觉得这个孩子变了，很多人会觉得是不是出现了一些心理方面的问题，其实如果他不影响学习和正常生活的话，这属于正常的青春期变化，是一个人生关键期和转折期，所以这个时候要给以很好的关心关注，给他支持，所以家长要有正确的认知。",
        "但是，如果这个问题超出了一般的青春期逆反表现的程度，比如说给他的情绪行为带来了很严重的困扰，影响到了他的社会功能，影响了学习，影响了生活，甚至影响了别人，干扰了别人，这个时候就不能简单归结为青春期的问题了，所以我觉得青春期的问题是非常关键的一个重要时期，正确的引导对孩子一生的发展都非常关键，对他的独立性，对他的成长都很关键。所以，我们要学会识别孩子到底是心理障碍还是正常的儿童心理发展、青春期的变化，这样才能够真正的准确发现问题，来促进孩子心理健康的正常发展。谢谢。”",
        "凤凰卫视记者：“请问李司长，目前国家卫健委在促进儿童青少年心理健康方面取得了哪些进展？谢谢。”",
        "国家卫生健康委医政司副司长——李大川：",
        "“谢谢您的提问。刚才，我向大家简单通报了近年来我们在推进儿童心理健康方面做的工作，总体上讲，我们认真贯彻党的新时代卫生健康工作方针，积极会同相关部门大力加强儿童青少年心理健康保障机制和工作体系建设，应该说我们儿童青少年心理健康的各项工作取得了积极进展，也在不断地提质增面。概括起来有几个方面：",
        "一是有关儿童青少年心理健康的保障机制逐步健全。《精神卫生法》《基本医疗卫生与健康促进法》这两部法律的实施，为做好儿童青少年心理健康工作提供了法律保障。同时，《中华人民共和国国民经济和社会发展第十四个五年规划和2035年远景目标规划纲要》的实施，也为做好相关工作指明了方向，确定了清晰的思路。",
        "二是有关儿童青少年心理健康服务体系逐步健全。我刚才介绍了“健康中国行动”中三个专项行动，另外我们开展全国社会心理服务体系试点，同时把社会心理服务相关工作纳入重大公共卫生服务项目，这些专项行动和专项工作的实施，都为推进心理健康工作各部门参与和加强体系建设提供了非常好的保障，相关的工作模式和实践路径都取得了积极进展，积累了有益经验。",
        "三是全社会关心重视儿童青少年心理健康的氛围逐渐浓厚。我们在医生培养培训过程中专门强调要增加心理健康方面的知识，提高所有医务人员心理健康方面的意识，让他们能够认识到心理健康问题，同时掌握处理基本心理健康问题的知识和常识，这个是很重要的。另外，我们也积极推动各个部门，在全国加强科普宣传，提高全社会对心理健康特别是儿童青少年心理健康的重视程度，提高儿童和家长的心理健康素养，整个社会越来越关注心理健康工作。",
        "这三个方面的积极成绩，为我们下一步做好儿童心理健康工作打下了很好的基础，我们还会在此基础上进一步推进。谢谢。”",
        "封面新闻记者：“对于已经出现心理健康问题的儿童青少年，我们应当如何有效的进行干预？谢谢。”",
        "首都医科大学附属北京安定医院儿童精神医学首席专家——郑毅：",
        "“谢谢你的提问。孩子如果已经出现心理健康问题的时候，我们应该怎么去干预，这个问题实际上很关键。我们从临床分型干预的模型来看，把它分成四个级别。",
        "第一个是儿童青少年心理健康行为只有问题，这是一个非常重要的关口期，如果只是发展过程中一个小的问题或者适应方面小的困难，但还没有达到明显的功能损伤，还没有影响到学习和社交功能，这时候我们指导家长、老师给学生心理方面的辅导，很多孩子是可以调整过来的。如果说问题比较明显的，可以到学校的心理辅导室，现在学校有心理辅导室，社区也有一些心理辅导中心，所以我觉得如果说家长或者老师还有个人调节起来有困难的时候，这一级别的时候可以去这些机构得到比较重要的帮助，可以解决他们的问题。",
        "还有一些问题没有达到疾病的诊断，但是已经出现了综合征，这个时候我觉得就需要专业人员进一步的评估，可能要介入心理治疗等治疗手段。比如有认知行为治疗、辩证行为治疗、家庭治疗，还有一些心理方面的辅导干预，这些心理治疗方面，如果在这个层面是可以得到很好的帮助和治疗的。",
        "最后一个层面是达到了疾病，这时候就应该及早的进行评估诊断，最好能够尽早去看医生进行正确的评估，积极的医疗干预也是非常重要的。",
        "医疗干预有多种干预方法，最常见的是药物治疗，还有心理治疗，还有一些物理疗法。我们比较主张症状轻微的开始是行为心理干预，如果比较严重的积极配合药物治疗也是非常必要的。",
        "最后我想强调，一些治疗是综合的长期的治疗过程，这一点大家要有真正的正确认识。所以医疗机构、学校、家庭、社区都要积极的配合，我觉得这也很关键。要有足够的耐心和包容度，要给孩子们营造良好的社会环境。要促进其恢复和维持良好的社交和环境，来帮助他们彻底恢复，这才是比较好的治疗模式。谢谢。”",
        "中新社记者：“请问，在促进儿童青少年心理健康方面，家长和老师应该发挥哪些作用？谢谢。”",
        "教育部体卫艺司副司长一级巡视员——刘培俊：",
        "“感谢您关心如何发挥家长和老师的作用，特别是家校协同的作用，来做好学生的心理健康工作。当前学校、家庭、社会越来越多的方面关心关注学生的心理健康，可以说在呵护儿童心理健康的多方主体当中，离学生最近的就是老师、家长还有学生本人。国家政策也明确要求，把学生的心理健康工作要融入到家庭、学校、社区的各个环境当中，多年来各地和学校也创造积累了很好的经验，家校协同，家长老师发挥作用，对孩子的心理健康，做实做细相关工作。",
        "第一，学校用心关心关爱学生，让学生“在校如在家”。学校树牢“健康第一”的教育理念，把学生身心健康作为考核学校教育教学工作的重要指标，要呵护学生身心健康，在健康放在第一位的基础上开展教育教学各项改革和各项教学活动。一是坚持全过程育人。学校设计思想教育、学科教学、科学研究、体育锻炼、艺术活动、劳动任务，社会实践等各项活动，让学生在喜闻乐见、丰富多彩的活动当中能够愉悦身心、增长学识，应对挫折，管理自我，健康成长。二是发挥全员育人的作用。不仅是心理健康老师、班主任和辅导员，而且强调学校的各科老师以及教职员工，都担负起学生心理健康工作的一份责任，关心关爱学生的学习生活，以及他们的思想，营造融洽的师生关系和同学关系，让学生真正的小我融入到大我，让个体融入到学校集体的大家庭。",
        "第二，家庭善于教育子女，让孩子“在家如在校”。家庭是孩子的第一所学校，也是一所特殊的校园，家长是学生的第一任老师，也是一对一的得力的老师。现在家长做了大量工作，主动配合学校做好子女的心理健康教育，树立正确的家教观、育儿观和成才观，掌握儿童和青少年心理健康教育的方法和技能，经常主动关心子女的心理状况、情绪变化和内心感受，平等地对话沟通，做好心理教育，寻求咨询服务和必要的干预，营造良好的家庭育人环境。同时，家长要做到以身示范，带领孩子快乐成长。",
        "第三，学生学会管理自己，让健康”在我如在他”。学生不仅接受家庭学校他人关心，也要当好自己健康第一责任人。自觉学习心理健康的观念意识、知识和技能、方式方法，具备健康素养和健康管理的能力。特别是当自己心理困惑、情绪不好或者是遇到挫折的时候，能够静下心来想办法，正面评价自己，同时鼓起勇气去求别人，相信自己能够通过自己的努力以及他人、朋友的帮助，能够很快地走出困境，恢复到正常的心态。",
        "家校协同是各地开展学生心理健康教育的一个成功办法，所以家长、老师和学生都有自己的一份任务和职责。未来老师和家长能够继续携起手来，为学生营造一个良好的家庭环境、校园环境和成长生态。谢谢。”",
        "每日经济新闻记者：“我的问题想提给专家。儿童青少年心理疾病的治疗方法有哪些？您刚才也提到了药物治疗，常见的药物治疗是否会有一些副作用呢？谢谢。”",
        "首都医科大学附属北京安定医院儿童精神医学首席专家——郑毅：",
        "“谢谢您的提问。我觉得治疗很关键，因为心理出现了问题，治疗的方法我刚才谈到了一些，确实药物治疗也是很关键的治疗方法。其实精神科的用药是相对来讲比较复杂的，因为他有很多类药物，我们大部分的药物都是比较安全，都经过临床验证，各方面治疗经验都比较多，而且新的药物越来越多，所以药物治疗还是相对比较安全的。在积极的监控下，定期做一些检查，药物治疗是非常安全的。我们观察到基本都是非常安全的，但是要注意监测。",
        "心理治疗也是大家比较关注的问题，很多认知水平出了问题，通过行为也可以改变。我还是比较强调综合治疗，需要药物的时候积极的用药物治疗，需要心理治疗和行为干预的要积极进行，有很多疾病实际上跟生活方式有密切关系，有些孩子睡眠不足的，要把睡眠调整好。所以，精神治疗应该是一个比较综合性的治疗。谢谢。”",
        "主持人、国家卫生健康委新闻发言人、宣传司副司长——米锋：“谢谢，最后一个问题。”",
        "南方日报、南方+记者：“通过刚刚的发言，我们了解到卫生健康和教育部门在儿童心理健康方面做了大量工作，想请两个部门的领导人讲一下做好这项工作的关键是什么？”",
        "国家卫生健康委医政司副司长——李大川：",
        "“谢谢您的提问。这些年，我们在开展儿童青少年心理健康工作过程中，也在边工作边研究总结，总体上我们体会到，要做好这方面的工作，不断增进儿童青少年心理健康水平有几个关键的点。",
        "第一，党政领导，齐抓共管。儿童青少年心理健康问题涉及到各个领域，需要各部门、各个行业甚至全社会都来关注，因此把这项工作纳入社会服务体系，纳入公共卫生服务体系，纳入社会保障体系来协同推进、共同发力是非常重要的。",
        "第二，加强体系建设，提供全流程的服务。必须针对不同阶段、不同程度的心理健康问题提供分层次的、分级分类的医疗服务，来提高心理健康服务的水平和针对性。",
        "第三，要加大保障力度，完善支持政策。近年来，中央财政安排了专项资金，支持相关项目开展，包括中央转移地方支持的社会心理服务体系建设试点，常见精神障碍防治和儿童青少年心理健康促进等工作，通过试点取得经验进行推广。同时，很多地方也主动开展工作，在资源配置、财政投入、人才培养各方面出了很多保障措施，这些措施共同推进了行业加强心理健康工作的积极性，对我们做好工作提供非常好的支撑。",
        "我们在工作过程中也深刻体会到，儿童青少年心理健康工作关键是要坚持预防为主、群防群控。做好预防端的工作是最重要的，因此必须减少和降低产生儿童青少年心理健康问题的危害性因素，这是很重要的。同时，要通过营造良好的家庭、学校、社会环境，来减轻青少年儿童和家长的心理压力。通过教育引导，树立自尊自信、理性平和、积极向上的社会心态，要做好这样的工作就要加强宣传工作，包括行业内的宣传也包括全社会的宣传，让全社会都提高这方面的认识，重视这项工作。在各部门制定政策的时候都能够把心理健康融入进来。同时，进一步完善相关的考核和推进机制，压实工作责任，调动全社会的积极性。",
        "上述工作我们都在积极推进过程中，下一步我们还会继续加大工作力度。谢谢。”",
        "教育部体卫艺司副司长一级巡视员——刘培俊：",
        "“您关注并提出了这个问题，当前促进儿童青少年心理健康的关键，这也是教育部门和学校多年来在探索和聚焦的一个领域。实践也表明，改善儿童青少年的心理健康状况，促进学生心理健康要抓住关键。除了在政策体系、体制机制、经费保障这些宏观部署方面，在业务层面要聚焦几个关键点，包括重视关键时期、优化关键因素、抓住关键环节。",
        "第一个是重视学生成长的关键时期，护航孩子成长。在人生的旅途当中，儿童青少年这个时期正处于身体发育、心理成长和人格养成的关键时期，也是世界观、价值观、人生观形成的关键时期，内心容易受到外界环境的影响而发生心理变化。我们知道，身体有时感冒发烧，心理也会出现不适，一般我们比较关注儿童青少年身体方面的疾病，但心理健康问题也需要早预防、早发现、早干预、早康复，早日恢复到正常的健康状态。",
        "第二个是优化心理影响的关键因素，营造健康的环境。影响儿童青少年心理的因素是比较多的，但是儿童青少年经常会遇到的像考试与学业、升学与求职，包括手机与网络、情感与人际，还有自我与评价，这些因素是影响儿童青少年心理的关键之所在，所以需要家庭、学校和社会加以重点关注，共同引导帮助学生妥善把握住，恰当处理好这些影响心理健康的关键因素，营造有利于学生身心健康的育人生态。",
        "第三个关键是抓好心理工作的关键环节，确保规范有效。目前，已经建立起了预警监测、健康教育、咨询服务、干预处置“四位一体”的工作体系，这四个环节当中，其中健康教育十分关键，可以说是一个前提，它既是培养学生健康向上心理优秀品质的关键，也是抓早抓小预防心理健康问题的关键，所以希望学校和家长继续家校协同，共同做好学生和子女的心理健康教育，让学生具备心理健康的素养，具备心理健康方面的知识技能、方式方法，学校特别是要按要求继续开好心理健康的必修课、选修课、主题活动、日常教育等，真正把健康教育做好，把预防关口扎牢，不断提高学生的心理健康水平。谢谢。”",
        "主持人、国家卫生健康委新闻发言人、宣传司副司长——米锋：",
        "“谢谢。今天的发布会几位嘉宾介绍了我国儿童青少年促进心理健康的有关情况，再次感谢各位。后续我们还将继续就卫生健康领域的相关工作贯彻落实党的二十大精神的具体举措召开新闻发布会，欢迎大家继续关注。今天的发布会到此结束，谢谢大家。”"
      ]

    },
    {
      id: 6,
      title: "“她那么爱笑，为什么还会得抑郁症？”",
      time: {
        month: "07/06",
        year: "2023",
      },
      from: "来源于央视新闻 发表于北京",
      content: [
        "5日晚，歌手李玟的家人通过社交媒体宣布，李玟于7月2日在家中轻生，送院后一直昏迷，最终于7月5日离世。家人表示，李玟于数年前患上抑郁症，与病魔经过了长时间斗争，可惜近日病情急转直下。",
        "李玟离世的消息令不少网友痛心，也有人感到震惊。在大众印象中，李玟总是呈现出阳光、乐观的形象，有网友疑惑：为什么看起来阳光开朗的人，也会得抑郁症？这其实是很多人对抑郁症的误解。",
        "01、爱笑的人就不会得抑郁症吗？",
        "“抑郁症”是一种常见心理疾病，主要特征为持续情绪低落、对平时喜爱的事物失去兴趣或动力、自我评价低、有消极言行等。",
        "大部分的人对抑郁症存在刻板印象，觉得患者都是闷闷不乐、对生活毫无兴趣的人，阳光、爱笑的人不会得抑郁症。实际上，抑郁症有其诊断标准，也有自己的发生、发展规律，并不是每个抑郁的人都会展现出悲观的状态，不能简单以人表现出来的个性判断。",
        "部分抑郁症患者患有“微笑抑郁症”。这是一个通俗的说法，指带有抑郁情绪的人把抑郁的一面掩饰起来，戴着微笑的面具，让人以为自己生活愉快，导致抑郁症状很难被发现。这类患者难以及时得到外界帮助，进而更容易陷入孤立无援的境地。",
        "02、如何识别抑郁症？",
        "抑郁症产生的最关键原因是动力缺乏和快感缺失，就像列车失去了燃料和动力装置，导致患者无法维系原来的生活状态。严重时，患者生活陷入停滞，不仅维持不了高级的社交工作能力，基础的生理状态如饮食和睡眠也出现问题，甚至产生精神类症状，想要自杀。",
        "抑郁症的症状多种多样、千变万化，个体差异很大，但是大致可以归为以下几类。",
        "1、心境低落：这是最核心的症状，主要表现为显著而持久的情绪低落和心态悲观，程度轻重不等。轻度患者会闷闷不乐、缺乏愉快感、兴趣缺失，重度患者会感到悲观绝望、度日如年、痛不欲生。",
        "2、思维障碍：患者常感觉思维变慢、脑子变空，反应慢、记不住事情。思维内容多悲观消极，严重时患者甚至出现妄想等精神类症状，比如因躯体不适而怀疑自己患有重病，也可能会出现关系妄想、贫穷妄想、被害妄想等，部分患者还可能出现幻觉，常为听幻觉。",
        "3、意志活动减退：表现为没有意愿和动力做事。比如生活懒散，不愿社交，长时间独处，严重时不顾个人卫生，甚至不语、不动、不食。",
        "4、认知功能损害：主要表现为记忆力下降，注意力下降或学习费力，总是回忆过去不开心的事情，或者总是想些悲观的事。",
        "5、躯体症状：常见症状包括睡眠障碍、乏力、食欲减退、体重下降、便秘、疼痛（身体任何部位）、性欲减退、阳痿、闭经和自主神经功能失调等。",
        "03、抑郁症可以治疗吗？",
        "专家指出，作为一种疾病，抑郁症并非“不治之症”。通过寻求专业的帮助，大多数患者可以被治愈。",
        "和大部分疾病都一样，抑郁症越早治疗效果越好。最好是在刚刚出现抑郁情绪的时候，及时寻求心理干预，从而避免抑郁症。",
        "目前，抑郁症用药的普遍规律是从低剂量开始，逐渐加量，最后达到目标剂量维持，连续用药治疗时间多要达到两年甚至更长。尽管过程很漫长，但真正达到治愈的患者，完全可以恢复正常的工作和生活，而且在遇到挫折时，经历过低谷的他们有时会比普通人更坚强。",
        "04、如何与抑郁症患者相处？",
        "很多时候，抑郁症患者身边的亲友，因为对病情不了解，从而对抑郁症患者的各类行为产生误解。与抑郁症患者相处时，周围的人往往也会不知所措，生怕一不小心就伤害对方。",
        "如何通过自己的言行，帮助和安慰抑郁症患者，和他们和谐友好地相处？",

        "（1）多理解",
        "让抑郁症患者获得尊重和正在尝试被理解的感觉很重要。他们的很多痛苦不为人知，也很难一次性表达清楚，因此要不断地尝试多理解他们。",
        "（2）多倾听",
        "倾听对抑郁症患者最重要。倾听过后，我们最好不加评价、不加分析、不加指责。",
        "（3）多关心",
        "抑郁症患者通常很脆弱，需要被关心和爱护。",
        "（4）多配合",
        "有的患者想单独待会儿，我们就要在约定安全后给予一些空间和自由。有的患者容易哭泣和发泄，我们就要给予陪伴，允许他在不伤害自己的限度内发泄。",
        "（5）不盲目建议、鼓励和督促",
        "抑郁症患者处于疾病的状态下，承受力和对外界的敏感度异于日常状态，各类微小的事情都会带给他们压力和崩溃感。",
        "没有专业的患者病情评估和医师指导，不应强行带患者运动、聚会、旅行、读书及尝试各类网上查阅到的治疗方法和调整方式。",
        "（6）不指责",
        "抑郁症患者最害怕被贴上不努力、不负责、不认真、不坚持等标签。不要说“这算个什么事儿”“人家的处境比你还难，也没有抑郁”。",
        "（7）不询问原因",
        "不要直接问“为什么你抑郁”“为什么大家都有压力，就你生病”。抑郁症是一种病因复杂的疾病，我们无法选择自己是否要患病。",
        "（8）不战战兢兢地对待",
        "尽力去做，带着爱去看护抑郁症患者就是最好的应对办法，不用背负太多心理负担，不要过于责备自己没有照顾好他们。",
        "（9）找专业医生做依靠",
        "专业的精神心理科医生会根据患者的疾病状态，判断患者是否需要药物干预、目前最该做的和最不该做的事情等，并给出相关治疗方案。",
      ],
    },
    {
      id: 1,
      title: "【七一建党节】102年，“变”与“不变”",
      time: {
        year: "2023",
        month: "07/01",
      },
      from: "来源 | 央视新闻",
      content: [
        "今年是中国共产党成立102周年。一百多年来，一代代中国共产党人心怀信仰、踔厉奋发、勇毅前行，将一项项伟大成就写进史册。这些年来，既有翻天覆地的“改变”，也有恒久坚持的“不变”。回望百年征程，感受“变”与“不变”↓↓↓",
      ],
    },
    {
      id: 2,
      title: "2023全国青少年心理健康家校社联防计划在京启动",
      time: {
        year: "2023",
        month: "05/26",
      },
      from: "来源于央视网",
      content: [
        "5月25日是全国大中学生心理健康日，当天，由中国妇女杂志社联合北京理工大学附属中学等单位共同推出的“2023全国青少年心理健康家校社联防计划”在北京理工大学附属中学第18届心理健康节活动中正式启动。该计划旨在贯彻落实《全面加强和改进新时代学生心理健康工作专项行动计划（2023-2025年）》，进一步推动造就德智体美劳全面发展的社会主义建设者和接班人。该计划得到中国科学院心理研究所、北京师范大学心理学部、中华儿促会家校社共育工作委员会、清华大学幸福科技实验室的大力支持。",
        "中国妇女杂志社社长綦淑娟介绍，当前，心理健康问题呈现多元化、低龄化的发展趋势，加强对青少年心理健康的保护尤为迫切和重要。通过此次联防计划的推出，将形成家校社三方合力，进一步织密关注、关心、关爱青少年的心理防护网，使每一名青少年都拥有健康心理、优秀品质、高尚人格，在人生旅途上心光灿烂、阔步前行。",
        "北京市海淀区教育委员会主任杜荣贞表示，教育和预防是解决青少年心理健康问题的第一要务。在孩子成长过程中，家校社缺一不可，家庭是心灵的港湾，学校是成长的沃土，社会是锻炼的熔炉。未来，海淀区各单位、各部门将继续通力合作、继续落实立德树人根本任务，发挥各方优势，共同推动构建、完善家庭、学校、社会“三位一体”的心理健康教育协同联动工作体系，全方位守护学生心理健康。",
        "北京理工大学附属中学党委书记、校长毛强介绍了学校心理健康教育工作的实践和思考。学校依托家校社资源的开发和整合，从心理、生涯和融合教育三方面入手，在满足发展、预防和干预不同层级需要的基础上，以十二年贯通的成长型思维，构建了“五全”心理健康教育体系。未来学校将继续优化完善心理健康教育体系，强化学生心理危机预警机制，有效助力创新人才贯通培养，护航学生健康成长。",
        "活动现场，中国妇女杂志社为参与计划的专家颁发证书，并为合作机构授牌。中国家庭文化研究会副会长、乒乓球世界冠军邓亚萍通过视频做《体育是最好的挫折教育》主题分享。中国科学院心理研究所副研究员张兴利作为该计划特聘专家，以“自主支持，助力青少年健康发展”为主题，为老师和家长进行了讲座。",
        "据悉，本次启动仪式结合了北京理工大学附属中学第18届心理健康节系列活动，中国妇女杂志社社长綦淑娟，北京市海淀区人大常委会主任刘长利，北京市海淀区教育委员会主任杜荣贞，北京市海淀区妇联主席吴红蓉等与会嘉宾出席启动仪式，并观摩了北京理工大学附属中学心理游园会、“迈好青春第一步”14岁主题班会等健康节活动。",
      ],
    },
    {
      id: 3,
      title:
        "重磅！教育部等十七部门联合印发全面加强和改进新时代学生心理健康工作专项行动计划",
      time: {
        year: "2023",
        month: "05/11",
      },
      from: "本文发表于北京 来源于中国教育报",
      content: [
        "为认真贯彻党的二十大精神，贯彻落实《中国教育现代化2035》《国务院关于实施健康中国行动的意见》，全面加强和改进新时代学生心理健康工作，提升学生心理健康素养，经中央教育工作领导小组会议审议通过，近日，教育部等十七部门联合印发《全面加强和改进新时代学生心理健康工作专项行动计划（2023—2025年）》（以下简称《行动计划》）。",
        "一是五育并举促进心理健康，坚持学习知识与提高全面素质相统一，培养德智体美劳全面发展的社会主义建设者和接班人。",
        "《行动计划》部署开展八项重点工作：",
        "二是加强心理健康教育，开设相关心理健康课程，发挥课堂教学作用，关注学生个体差异，帮助学生掌握心理健康知识和技能。",
        "三是规范心理健康监测，坚持预防为主、关口前移，定期开展学生心理健康测评。",
        "四是完善心理预警干预，健全预警体系，加强物防、技防建设，及早发现学生严重心理健康问题，畅通预防转介干预就医通道，及时转介、诊断、治疗。",
        "五是建强心理人才队伍，提升人才培养质量，配齐心理健康教师，畅通教师发展渠道。",
        "六是支持心理健康科研，开展学生心理健康基础性、前沿性和国际性研究，推动成果应用。",
        "七是优化社会心理服务，提升社会心理服务能力，加强家庭教育指导服务，加强未成年人保护。",
        "八是营造健康成长环境，广泛开展学生心理健康知识和预防心理问题科普，推广学生心理健康工作经验做法，加强日常监督管理。",
        "《行动计划》强调，完善各项保障措施。一是加强组织领导，将学生心理健康工作纳入对省级人民政府履行教育职责的评价，纳入学校改革发展整体规划，纳入人才培养体系和督导评估指标体系，作为各级各类学校办学水平评估和领导班子年度考核重要内容。二是落实经费投入，加大统筹力度，优化支出结构，切实加强学生心理健康工作经费保障。三是培育推广经验，支持有条件的地区和学校创新学生心理健康工作模式，发挥引领和带动作用。",
        "具体内容一起来看——",
        "全面加强和改进新时代学生心理健康工作专项行动计划（2023—2025年）",
        "n促进学生身心健康、全面发展，是党中央关心、人民群众关切、社会关注的重大课题。随着经济社会快速发展，学生成长环境不断变化，叠加新冠疫情影响，学生心理健康问题更加凸显。为认真贯彻党的二十大精神，贯彻落实《中国教育现代化2035》《国务院关于实施健康中国行动的意见》，全面加强和改进新时代学生心理健康工作，提升学生心理健康素养，制定本行动计划。",
        "一、总体要求",
        "（一）指导思想",
        "《行动计划》坚持以习近平新时代中国特色社会主义思想为指导，全面贯彻党的教育方针，坚持为党育人、为国育才，落实立德树人根本任务，坚持健康第一的教育理念，切实把心理健康工作摆在更加突出位置，促进学生思想道德素质、科学文化素质和身心健康素质协调发展，培养担当民族复兴大任的时代新人。",
        "以习近平新时代中国特色社会主义思想为指导，全面贯彻党的教育方针，坚持为党育人、为国育才，落实立德树人根本任务，坚持健康第一的教育理念，切实把心理健康工作摆在更加突出位置，统筹政策与制度、学科与人才、技术与环境，贯通大中小学各学段，贯穿学校、家庭、社会各方面，培育学生热爱生活、珍视生命、自尊自信、理性平和、乐观向上的心理品质和不懈奋斗、荣辱不惊、百折不挠的意志品质，促进学生思想道德素质、科学文化素质和身心健康素质协调发展，培养担当民族复兴大任的时代新人。",
        "（二）基本原则",
        "——坚持全面发展。完善全面培养的教育体系，推进教育评价改革，坚持学习知识与提高全面素质相统一，培养德智体美劳全面发展的社会主义建设者和接班人。",
        "——坚持健康第一。把健康作为学生全面发展的前提和基础，遵循学生成长成才规律，把解决学生心理问题与解决学生成才发展的实际问题相结合，把心理健康工作质量作为衡量教育发展水平、办学治校能力和人才培养质量的重要指标，促进学生身心健康。",
        "——坚持提升能力。统筹教师、教材、课程、学科、专业等建设，加强学生心理健康工作体系建设，全方位强化学生心理健康教育，健全心理问题预防和监测机制，主动干预，增强学生心理健康工作科学性、针对性和有效性。",
        "——坚持系统治理。健全多部门联动和学校、家庭、社会协同育人机制，聚焦影响学生心理健康的核心要素、关键领域和重点环节，补短板、强弱项，系统强化学生心理健康工作。",
        "（三）工作目标",
        "健康教育、监测预警、咨询服务、干预处置“四位一体”的学生心理健康工作体系更加健全，学校、家庭、社会和相关部门协同联动的学生心理健康工作格局更加完善。2025年，配备专（兼）职心理健康教育教师的学校比例达到95%，开展心理健康教育的家庭教育指导服务站点比例达到60%。",
        "二、主要任务",
        "（一）五育并举促进心理健康",
        "1.以德育心。将学生心理健康教育贯穿德育思政工作全过程，融入教育教学、管理服务和学生成长各环节，纳入“三全育人”大格局，坚定理想信念，厚植爱国情怀，引导学生扣好人生第一粒扣子，树立正确的世界观、人生观、价值观。",
        "2.以智慧心。优化教育教学内容和方式，有效减轻义务教育阶段学生作业负担和校外培训负担。教师要注重学习掌握心理学知识，在学科教学中注重维护学生心理健康，既教书，又育人。",
        "3.以体强心。发挥体育调节情绪、疏解压力作用，实施学校体育固本行动，开齐开足上好体育与健康课，支持学校全覆盖、高质量开展体育课后服务，着力保障学生每天校内、校外各1个小时体育活动时间，熟练掌握1—2项运动技能，在体育锻炼中享受乐趣、增强体质、健全人格、锤炼意志。",
        "4.以美润心。发挥美育丰富精神、温润心灵作用，实施学校美育浸润行动，广泛开展普及性强、形式多样、内容丰富、积极向上的美育实践活动，教会学生认识美、欣赏美、创造美。",
        "5.以劳健心。丰富、拓展劳动教育实施途径，让学生动手实践、出力流汗，磨炼意志品质，养成劳动习惯，珍惜劳动成果和幸福生活。",
        "（二）加强心理健康教育",
        "6.开设心理健康相关课程。中小学校要结合相关课程开展心理健康教育。中等职业学校按规定开足思想政治课“心理健康与职业生涯”模块学时。高等职业学校按规定将心理健康教育等课程列为公共基础必修或限定选修课。普通高校要开设心理健康必修课，原则上应设置2个学分（32—36学时），有条件的高校可开设更多样、更有针对性的心理健康选修课。举办高等学历继续教育的高校要按规定开设适合成人特点的心理健康课程。托幼机构应遵循儿童生理、心理特点，创设活动场景，培养积极心理品质。",
        "7.发挥课堂教学作用。结合大中小学生发展需要，分层分类开展心理健康教学，关注学生个体差异，帮助学生掌握心理健康知识和技能，树立自助、求助意识，学会理性面对困难和挫折，增强心理健康素质。",
        "8.全方位开展心理健康教育。组织编写大中小学生心理健康读本，扎实推进心理健康教育普及。向家长、校长、班主任和辅导员等群体提供学生常见心理问题操作指南等心理健康“服务包”。依托“师生健康 中国健康”主题教育、“全国大中学生心理健康日”、职业院校“文明风采”活动、中考和高考等重要活动和时间节点，多渠道、多形式开展心理健康教育。发挥共青团、少先队、学生会（研究生会）、学生社团、学校聘请的社会工作者等作用，增强同伴支持，融洽师生同学关系。",
        "（三）规范心理健康监测",
        "9.加强心理健康监测。组织研制符合中国儿童青少年特点的心理健康测评工具，规范量表选用、监测实施和结果运用。依托有关单位组建面向大中小学的国家级学生心理健康教育研究与监测专业机构，构建完整的学生心理健康状况监测体系，加强数据分析、案例研究，强化风险预判和条件保障。国家义务教育质量监测每年监测学生心理健康状况。地方教育部门和学校要积极开展学生心理健康监测工作。",
        "10.开展心理健康测评。坚持预防为主、关口前移，定期开展学生心理健康测评。县级教育部门要组织区域内中小学开展心理健康测评，用好开学重要时段，每学年面向小学高年级、初中、高中、中等职业学校等学生至少开展一次心理健康测评，指导学校科学规范运用测评结果，建立“一生一策”心理健康档案。高校每年应在新生入校后适时开展心理健康测评，鼓励有条件的高校合理增加测评频次和范围，科学分析、合理应用测评结果，分类制定心理健康教育方案。建立健全测评数据安全保护机制，防止信息泄露。",
        "（四）完善心理预警干预",
        "11.健全预警体系。县级教育部门要依托有关单位建设区域性中小学生心理辅导中心，规范心理咨询辅导服务，定期面向区域内中小学提供业务指导、技能培训。中小学校要加强心理辅导室建设，开展预警和干预工作。鼓励高中、高校班级探索设置心理委员。高校要强化心理咨询服务平台建设，完善“学校—院系—班级—宿舍/个人”四级预警网络，辅导员、班主任定期走访学生宿舍，院系定期研判学生心理状况。重点关注面临学业就业压力、经济困难、情感危机、家庭变故、校园欺凌等风险因素以及校外实习、社会实践等学习生活环境变化的学生。发挥心理援助热线作用，面向因自然灾害、事故灾难、公共卫生事件、社会安全事件等重大突发事件受影响学生人群，强化应急心理援助，有效安抚、疏导和干预。",
        "12.优化协作机制。教育、卫生健康、网信、公安等部门指导学校与家庭、精神卫生医疗机构、妇幼保健机构等建立健全协同机制，共同开展学生心理健康宣传教育，加强物防、技防建设，及早发现学生严重心理健康问题，网上网下监测预警学生自伤或伤人等危险行为，畅通预防转介干预就医通道，及时转介、诊断、治疗。教育部门会同卫生健康等部门健全精神或心理健康问题学生复学机制。",
        "（五）建强心理人才队伍",
        "13.提升人才培养质量。完善《心理学类教学质量国家标准》。加强心理学、应用心理学、社会工作等相关学科专业和心理学类拔尖学生培养基地建设。支持高校辅导员攻读心理学、社会工作等相关学科专业硕士学位，适当增加高校思想政治工作骨干在职攻读博士学位专项计划心理学相关专业名额。",
        "14.配齐心理健康教师。高校按师生比例不低于1：4000配备专职心理健康教育教师，且每校至少配备2名。中小学每校至少配备1名专（兼）职心理健康教育教师，鼓励配备具有心理学专业背景的专职心理健康教育教师。建立心理健康教育教师教研制度，县级教研机构配备心理教研员。",
        "15.畅通教师发展渠道。组织研制心理健康教育教师专业标准，形成与心理健康教育教师资格制度、教师职称制度相互衔接的教师专业发展制度体系。心理健康教育教师职称评审可纳入思政、德育教师系列或单独评审。面向中小学校班主任和少先队辅导员、高校辅导员、研究生导师等开展个体心理发展、健康教育基本知识和技能全覆盖培训，定期对心理健康教育教师开展职业技能培训。多措并举加强教师心理健康工作，支持社会力量、专业医疗机构参与教师心理健康教育能力提升行动，用好家校社协同心理关爱平台，推进教师心理健康教育学习资源开发和培训，提升教师发现并有效处置心理健康问题的能力。",
        "（六）支持心理健康科研",
        "16.开展科学研究。针对学生常见的心理问题和心理障碍，汇聚心理科学、脑科学、人工智能等学科资源，支持全国和地方相关重点实验室开展学生心理健康基础性、前沿性和国际性研究。鼓励有条件的高校、科研院所等设置学生心理健康实验室，开展学生心理健康研究。",
        "17.推动成果应用。鼓励支持将心理健康科研成果应用到学生心理健康教育、监测预警、咨询服务、干预处置等领域，提升学生心理健康工作水平。",
        "（七）优化社会心理服务",
        "18.提升社会心理服务能力。卫生健康部门加强儿童医院、精神专科医院和妇幼保健机构儿童心理咨询及专科门诊建设，完善医疗卫生机构儿童青少年心理健康服务标准规范，加强综合监管。民政、卫生健康、共青团和少先队、妇联等部门协同搭建社区心理服务平台，支持专业社工、志愿者等开展儿童青少年心理健康服务。对已建有热线的精神卫生医疗机构及12345政务服务便民热线（含12320公共卫生热线）、共青团12355青少年服务热线等工作人员开展儿童青少年心理健康知识培训，提供专业化服务，向儿童青少年广泛宣传热线电话，鼓励有需要时拨打求助。",
        "19.加强家庭教育指导服务。妇联、教育、关工委等部门组织办好家长学校或网上家庭教育指导平台，推动社区家庭教育指导服务站点建设，引导家长关注孩子心理健康，树立科学养育观念，尊重孩子心理发展规律，理性确定孩子成长预期，积极开展亲子活动，保障孩子充足睡眠，防止沉迷网络或游戏。家长学校或家庭教育指导服务站点每年面向家长至少开展一次心理健康教育。",
        "20.加强未成年人保护。文明办指导推动地方加强未成年人心理健康成长辅导中心建设，拓展服务内容，增强服务能力。检察机关推动建立集取证、心理疏导、身体检查等功能于一体的未成年被害人“一站式”办案区，在涉未成年人案件办理中全面推行“督促监护令”，会同有关部门全面开展家庭教育指导工作。关工委组织发挥广大“五老”优势作用，推动“五老”工作室建设，关注未成年人心理健康教育。",
        "（八）营造健康成长环境",
        "21.规范开展科普宣传。科协、教育、卫生健康等部门充分利用广播、电视、网络等媒体平台和渠道，广泛开展学生心理健康知识和预防心理问题科普。教育、卫生健康、宣传部门推广学生心理健康工作经验做法，稳妥把握心理健康和精神卫生信息发布、新闻报道和舆情处置。",
        "22.加强日常监督管理。网信、广播电视、公安等部门加大监管力度，及时发现、清理、查处与学生有关的非法有害信息及出版物，重点清查问题较多的网络游戏、直播、短视频等，广泛汇聚向真、向善、向美、向上的力量，以时代新风塑造和净化网络空间，共建网上美好精神家园。全面治理校园及周边、网络平台等面向未成年人无底线营销危害身心健康的食品、玩具等。",
        "三、保障措施",
        "（一）加强组织领导。将学生心理健康工作纳入对省级人民政府履行教育职责的评价，纳入学校改革发展整体规划，纳入人才培养体系和督导评估指标体系，作为各级各类学校办学水平评估和领导班子年度考核重要内容。成立全国学生心理健康工作咨询委员会。各地要探索建立省级统筹、市为中心、县为基地、学校布点的学生心理健康分级管理体系，健全部门协作、社会动员、全民参与的学生心理健康工作机制。",
        "（二）落实经费投入。各地要加大统筹力度，优化支出结构，切实加强学生心理健康工作经费保障。学校应将所需经费纳入预算，满足学生心理健康工作需要。要健全多渠道投入机制，鼓励社会力量支持开展学生心理健康服务。",
        "（三）培育推广经验。建设学生心理健康教育名师、名校长工作室，开展学生心理健康教育交流，遴选优秀案例。支持有条件的地区和学校创新学生心理健康工作模式，探索积累经验，发挥引领和带动作用。",
      ],
    },
    {
      id: 4,
      title: "全国首档！《全民认知健康》科普栏目上线",
      time: {
        month: "09/21",
        year: "2022",
      },
      from: "本文发表于北京 ，来源于人民康养",
      q: [1, 2, 3],
      content: [
        "9月21日，人民康养在人民日报社发布“全民认知健康科普计划”，并上线《全民认知健康》公益科普栏目宣传片，该栏目旨在向全民科普认知障碍相关疾病，共同助力国家积极应对人口老龄化、全面建设健康中国。",
        "2022年9月21日是第29个世界阿尔茨海默病日，每年这一天，全世界60多个国家和地区将组织早防早智等宣传活动。阿尔茨海默病(Alzheimer disease,简称AD),俗称老年痴呆，该病已成为继心脑血管疾病和恶性肿瘤之后老年人致残致死的第三大疾病。我国现有患者人数超过千万，居世界第一。当天，人民康养在人民日报社发布“全民认知健康科普计划”，人民康养智库秘书长李贤娜介绍，该计划将联合名人明星、公益机构、行业协会等组织，通过线上科普栏目和线下走进康养机构等公益活动，提高全民认知健康水平。",
        "据悉，《全民认知健康》栏目是全国首档认知健康科普栏目，专注于认知障碍疾病相关文化、心理和医疗知识科普。公益宣传片当天在人民日报社新媒体大厦现场发布，据宣传片介绍，我国现有2.67亿老年人，伴随着老龄化加速发展，老年痴呆等认知障碍疾病越来越高发。我国现有轻度认知障碍患者约3877万，其中大部分会发展成为阿尔茨海默病。专家指出，早预防、早发现、早干预，不仅可以降低疾病发生率、延缓疾病发展，还可以提高老人生活质量，提升家庭幸福感。遗憾是，我国许多人对该疾病的认识仍存在误区，大量患者家庭认为老年人记忆力下降是自然衰老过程，他们没有及时防治。老人们从记忆障碍变成了老年痴呆，给家庭带来了巨大照护压力，也给经济社会带来了沉重负担。提高全民对认知障碍疾病防治的重视，刻不容缓。",
        "积极应对人口老龄化已成为国家战略，积极老龄观、健康老龄化理念正融入经济社会发展全过程。《健康中国行动（2019—2030年）》提出，“到2030年，65岁及以上人群老年期痴呆患病率增速下降”，这意味着应更广泛向社会普及认知障碍疾病的早期筛查、早期干预等知识。对此，《全民认知健康》栏目组表示，9月21日起人民康养在微博平台发布“全民认知健康”科普话题，预计话题曝光量将超过千万级。赵雅芝、黄渤、佟丽娅、李菲儿、杨紫、刘璇、杨迪、董力、金靖、阿达娃等演艺界、体育界明星也应邀参与积极引导，通过各方共同努力提高老年人健康水平，让更多人关注认知障碍疾病，让更多人了解老年痴呆防治知识，呼吁社会各界一起提高全民认知健康水平，共同助力国家积极应对人口老龄化、全面建设健康中国。",
        "据悉，人民康养由人民日报社《民生周刊》运营管理，人民日报社《民生周刊》是全国唯一专注民生的中央媒体，具有广泛的社会影响力。健康养老是重大民生工程，人民康养作为专业康养融媒体平台，贯彻落实党和政府积极应对人口老龄化、全面建设“健康中国”的国家战略，致力于打造人民日报社旗下第一康养品牌，成为新时代中国康养事业传播的国家队。",
      ],
    },
    {
      id: 5,
      title:
        "脑科学未来可期：“脑科学与类脑研究”再次被“十四五”规划列为7大“前沿项目”之一",
      time: {
        year: "2021",
        month: "03/20",
      },
      from: "本文内容由brainnews编辑部综合人民日报，国家科技部官网，同济大学，南京江北新区等报道",
      content: [
        "在今年两会审查的“十四五”规划中，“脑科学与类脑研究”被列7大为“科技前沿领域攻关”项目之一，这是继“十三五”规划后，再次被列为重大科技项目。",
        "“十四五”规划中，科技前沿领域攻关的七大项目为：“新一代人工智能”、“量子信息”、“集成电路”、“脑科学与类脑研究”、“基因与生物技术”、临床医学与健康“以及“深空深地深海和极地探测”。其中，“脑科学与类脑研究”主要包含“脑认知原理解析、脑介观神经联结图谱绘制，脑重大疾病机理与干预研究、儿童青少年脑智发育，类脑计算与脑机融合技术研发”内容。",
        "现世界各国将脑科学已上升为国家科技战略重点，脑科学领域的科技赛道正快速形成，涌现了一批颠覆性理论和革命性技术成果。 ",
        "在脑健康方面",
        "全球有4.5亿人患有脑疾病，占全部疾病的11%，包括孤独症、儿童多动症、智障等儿童期脑疾病，抑郁症、精神分裂症等成年期脑疾病，以及老年痴呆、帕金森病、中风等老年期脑疾病，覆盖了各年龄层，给国家、社会和家庭带来了沉重负担。面对这些脑疾病，现有的脑疾病药物治疗进展缓慢，继续深入开展的脑科学研究，包括发展非药的干预治疗方法，比如有研究显示，孤独症的干预康复和评价方法，可以提高孤独症等儿童期脑疾病评估和康复；针对孤独症的遗传学、表观遗传学及脑异常改变等机制进行深入研究，建立起客观诊疗指标，有可能在一定程度上解决目前抗抑郁药物临床起效慢、不良反应重的问题；又比如，基于分子细胞和动物模型水平的脑科学研究，人们提出了老年痴呆发病的新机制和改善核心症状的策略，取得了一系列的进展。",
        "在脑机接口方面",
        "脑机接口正成为人机交互的新途径与新手段，Facebook、谷歌、微软、Neuralink（马斯克所在公司）等科技巨头在非侵入式“脑机语音文本界面”技术、神经假体技术等脑机接口细分领域取得了实质性进展，以致于美国已将脑机接口技术作为管制技术严格出口。面对国际竞争，我国正在从跟跑向并跑前进，已形成了多支在国际上有影响力的脑机接口研究团队，他们在脑机接口专用芯片、深部脑刺激、稳态视觉诱发电位脑机接口、脑波音乐脑机接口系统、脑瘫康复脑机接口系统和注意功能调控脑机接口系统等领域具备了与国际同行竞争的实力。",
        "在儿童脑智开发方面",
        "儿童青少年是脑认知功能变化迅速、智力提升较快的关键期，也是出现认知障碍和学习困难、孤独症、抑郁症等精神疾患的高发期。脑科学通过对神经科学、心理学、教育学、分子遗传生物学等多学科的交叉融合研究，整合基因-环境-脑-行为多个层面，在儿童青少年脑认知功能发育定量评估和各类学习困难干预方面，积累了丰富的研究基础和实践经验，可进一步应用于提升我国儿童青少年的脑功能和技能学习效率方面。",
        "总体来说，当前脑科学的进展从研发预防、诊断、干预、治疗认知障碍重大脑疾病的新方法和新产品入手，正在促进脑健康水平的提供；通过构建新型无创脑机接口，在脑区、回路和系统水平上与大脑对话，正在建立脑调控和脑沟通的新途径；伴随人们对脑认知功能的动态发育规律的深度了解，正在形成促进儿童青少年健康成长的新教育模式；通过模仿神经元及其网络的信息传输与处理方式，正在构建节能高效的类脑智能技术，助推人工智能技术的升级换代。",
      ],
    },
  ],

  //解决方案
  solution: [
    //导航1的绑定对象
    {
      //对象名称
      name: "心理咨询室1",
      //段落数组
      paragraph: [
        "心理是人类在情感世界里流动的过程和结果。具体指生物对客观物质世界的主观反映，心理的表现形式叫做心理现象。" +
        "包括人的心理过程和心理特性，每个人的心理活动都有一个发生、发展、消失的过程。" +
        "人在活动的时候，通过各种感官来认识外部世界事物，通过头脑的活动思考着事物的因果" +
        "关系，并伴随着喜、怒、哀、乐等情感体验。这一切都折射着一系列心理现象的整个过程，即心理过程。其性质可分为三个方面，即认知过程、情感过程和意志过程，即知、情、意。",
        "心理是人类在情感世界里流动的过程和结果。具体指生物对客观物质世界的主观反映，心理的表现形式叫做心理现象。" +
        "包括人的心理过程和心理特性，每个人的心理活动都有一个发生、发展、消失的过程。" +
        "人在活动的时候，通过各种感官来认识外部世界事物，通过头脑的活动思考着事物的因果" +
        "关系，并伴随着喜、怒、哀、乐等情感体验。这一切都折射着一系列心理现象的整个过程，即心理过程。其性质可分为三个方面，即认知过程、情感过程和意志过程，即知、情、意。",
      ],
    },
    //导航2的绑定对象
    {
      name: "心理咨询室2",
      paragraph: ["", ""],
    },
    //导航3的绑定对象
    {
      name: "心理咨询室3",
      paragraph: ["", ""],
    },
    //导航4的绑定对象
    {
      name: "心理咨询室4",
      paragraph: ["", ""],
    },
  ],
  //方法，根据id查找数据,arr---筛选数组
  queryById(arr, id) {
    var i;
    for (i = 0; i < arr.length; i++) {
      if (arr[i].id === id) return i;
    }
  },
};

export default data;
